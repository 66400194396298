import React from 'react';
import { compose, withProps } from 'recompose';
import { withScope } from '@/contexts/ScopeContext';
import { withLabel } from '../../../../se/components/Label';
import ReportRecipients from '../../sensor/ReportRecipients';
import NumberInput from '../../../../se/components/inputs/NumberInput';
import ObjectInput from '../../../../se/components/inputs/ObjectInput';
import ToogleInput from '../../../../se/components/inputs/ToogleInput';
import HasPostOpNumberInput from '../../../../se/components/inputs/HasPostOpNumberInput';

export const TotalPatientsReceivedConfiguration = withProps({
  schema: {
    totalPatientsThreshold: compose(
      withLabel('Total Patients Threshold'),
      withProps({ name: 'totalPatientsThreshold' })
    )(NumberInput),
  },
})(ObjectInput);

export const AverageWaitingRoomTimeConfiguration = withProps({
  schema: {
    waitingRoomTimeThreshold: compose(
      withLabel('Waiting Room Time Threshold'),
      withProps({ name: 'waitingRoomTimeThreshold' })
    )(NumberInput),
  },
})(ObjectInput);

export const AveragePreOpTimeConfiguration = withProps({
  schema: {
    preopTimeThreshold: compose(
      withLabel('PreOp Time Threshold'),
      withProps({ name: 'preopTimeThreshold' })
    )(NumberInput),
  },
})(ObjectInput);

export const AverageReadyForAnesthesiaToReadyForORConfiguration = withProps({
  schema: {
    readyForAnesthesiaThreshold: compose(
      withLabel('Ready for OR to Ready For Anesthesia Threshold'),
      withProps({ name: 'readyForAnesthesiaThreshold' })
    )(NumberInput),
  },
})(ObjectInput);

export const AverageReadyForORtoPreOpExitConfiguration = withProps({
  schema: {
    readyForORThreshold: compose(
      withLabel('Ready for OR to PreOp Exit Threshold'),
      withProps({ name: 'readyForORThreshold' })
    )(NumberInput),
  },
})(ObjectInput);

export const AverageORTimeConfiguration = withProps({
  schema: {
    orTimeThreshold: compose(withLabel('OR Time Threshold'), withProps({ name: 'orTimeThreshold' }))(NumberInput),
  },
})(ObjectInput);

export const AverageCleaningTimeConfiguration = withProps({
  schema: {
    cleaningTimeThreshold: compose(
      withLabel('Cleaning Time Threshold'),
      withProps({ name: 'cleaningTimeThreshold' })
    )(NumberInput),
  },
})(ObjectInput);

export const AverageClosingTimeConfiguration = withProps({
  schema: {
    closingTimeThreshold: compose(
      withLabel('Closing Time Threshold'),
      withProps({ name: 'closingTimeThreshold' })
    )(NumberInput),
  },
})(ObjectInput);

export const AveragePopiTimeConfiguration = withProps({
  schema: {
    popiTimeThreshold: compose(
      withLabel('OR Turnover Time Threshold'),
      withProps({ name: 'popiTimeThreshold' })
    )(NumberInput),
  },
})(ObjectInput);

export const AveragePacuTimeConfiguration = withProps({
  schema: {
    pacuTimeThreshold: compose(withLabel('Pacu Time Threshold'), withProps({ name: 'pacuTimeThreshold' }))(NumberInput),
  },
})(ObjectInput);

export const AveragePostOpTimeConfiguration = withProps({
  schema: {
    postOpTimeThreshold: compose(withScope, withProps({ name: 'postOpTimeThreshold' }))(HasPostOpNumberInput),
  },
})(ObjectInput);

const AnalyticsConfigInputV2 = withProps({
  schema: {
    monthlyReportRecipients: compose(
      withLabel('Monthly report recipients'),
      withProps({ name: 'monthlyReportRecipients' })
    )(ReportRecipients),
    weeklyReportRecipients: compose(
      withLabel('Weekly report recipients'),
      withProps({ name: 'weeklyReportRecipients' })
    )(ReportRecipients),

    // orUtilThreshold: compose(
    //   withLabel('OR Utilization Threshold'),
    //   withProps({ name: 'orUtilThreshold' })
    // )(NumberInput),
    wrMinThreshold: compose(withLabel('Waiting Room Min'), withProps({ name: 'wrMinThreshold' }))(NumberInput),
    wrMaxThreshold: compose(withLabel('Waiting Room Max'), withProps({ name: 'wrMaxThreshold' }))(NumberInput),
    preOpMinThreshold: compose(withLabel('PreOp Min'), withProps({ name: 'preOpMinThreshold' }))(NumberInput),
    preOpMaxThreshold: compose(withLabel('PreOp Max'), withProps({ name: 'preOpMaxThreshold' }))(NumberInput),
    pipoMinThreshold: compose(
      withLabel('Patient In Patient Out Min'),
      withProps({ name: 'pipoMinThreshold' })
    )(NumberInput),
    pipoMaxThreshold: compose(
      withLabel('Patient In Patient Out Max'),
      withProps({ name: 'pipoMaxThreshold' })
    )(NumberInput),
    popiMinThreshold: compose(withLabel('OR Turnover Min'), withProps({ name: 'popiMinThreshold' }))(NumberInput),
    popiMaxThreshold: compose(withLabel('OR Turnover Max'), withProps({ name: 'popiMaxThreshold' }))(NumberInput),
    pacuMinThreshold: compose(withLabel('Pacu Min'), withProps({ name: 'pacuMinThreshold' }))(NumberInput),
    pacuMaxThreshold: compose(withLabel('Pacu Max'), withProps({ name: 'pacuMaxThreshold' }))(NumberInput),
    postOpMinThreshold: compose(withLabel('PostOp Min'), withProps({ name: 'postOpMinThreshold' }))(NumberInput),
    postOpMaxThreshold: compose(withLabel('PostOp Max'), withProps({ name: 'postOpMaxThreshold' }))(NumberInput),
    preOpToDischargedMinThreshold: compose(
      withLabel('PreOp to Discharge Min'),
      withProps({ name: 'preOpToDischargedMinThreshold' })
    )(NumberInput),
    preOpToDischargedMaxThreshold: compose(
      withLabel('PreOp to Discharge Max'),
      withProps({ name: 'preOpToDischargedMaxThreshold' })
    )(NumberInput),
    inOrMinThreshold: compose(withLabel('OR Min'), withProps({ name: 'inOrMinThreshold' }))(NumberInput),
    inOrMaxThreshold: compose(withLabel('OR Max'), withProps({ name: 'inOrMaxThreshold' }))(NumberInput),
    inSurgeryMinThreshold: compose(
      withLabel('In Surgery Min'),
      withProps({ name: 'inSurgeryMinThreshold' })
    )(NumberInput),
    inSurgeryMaxThreshold: compose(
      withLabel('In Surgery Max'),
      withProps({ name: 'inSurgeryMaxThreshold' })
    )(NumberInput),
    closingMinThreshold: compose(withLabel('Closing Min'), withProps({ name: 'closingMinThreshold' }))(NumberInput),
    closingMaxThreshold: compose(withLabel('Closing Max'), withProps({ name: 'closingMaxThreshold' }))(NumberInput),
    cleaningMinThreshold: compose(
      withLabel('Cleaning Time Min'),
      withProps({ name: 'cleaningMinThreshold' })
    )(NumberInput),
    cleaningMaxThreshold: compose(
      withLabel('Cleaning Time Max'),
      withProps({ name: 'cleaningMaxThreshold' })
    )(NumberInput),
    procedureCompleteMinThreshold: compose(
      withLabel('Post Closing Min'),
      withProps({ name: 'procedureCompleteMinThreshold' })
    )(NumberInput),
    procedureCompleteMaxThreshold: compose(
      withLabel('Post Closing Max'),
      withProps({ name: 'procedureCompleteMaxThreshold' })
    )(NumberInput),
    orReadyMinThreshold: compose(withLabel('OR Ready Min'), withProps({ name: 'orReadyMinThreshold' }))(NumberInput),
    orReadyMaxThreshold: compose(withLabel('OR Ready Max'), withProps({ name: 'orReadyMaxThreshold' }))(NumberInput),
    piToStartMinThreshold: compose(
      withLabel('Patient In to Start Min'),
      withProps({ name: 'piToStartMinThreshold' })
    )(NumberInput),
    piToStartMaxThreshold: compose(
      withLabel('Patient In to Start Max'),
      withProps({ name: 'piToStartMaxThreshold' })
    )(NumberInput),
    poToCutMinThreshold: compose(
      withLabel('Patient Out to Cut Min'),
      withProps({ name: 'poToCutMinThreshold' })
    )(NumberInput),
    poToCutMaxThreshold: compose(
      withLabel('Patient Out to Cut Max'),
      withProps({ name: 'poToCutMaxThreshold' })
    )(NumberInput),
    startToCloseMinThreshold: compose(
      withLabel('Start to Close Min'),
      withProps({ name: 'startToCloseMinThreshold' })
    )(NumberInput),
    startToCloseMaxThreshold: compose(
      withLabel('Start to Close Max'),
      withProps({ name: 'startToCloseMaxThreshold' })
    )(NumberInput),
    physicianPopiMinThreshold: compose(
      withLabel('Physician Turnover Min'),
      withProps({ name: 'physicianPopiMinThreshold' })
    )(NumberInput),
    physicianPopiMaxThreshold: compose(
      withLabel('Physician Turnover Max'),
      withProps({ name: 'physicianPopiMaxThreshold' })
    )(NumberInput),
    fcotsGracePeriod: compose(withLabel('FCOTS Grace Period'), withProps({ name: 'fcotsGracePeriod' }))(NumberInput),
    piToPhyiMinThreshold: compose(
      withLabel('Patient In to Physician In Min'),
      withProps({ name: 'piToPhyiMinThreshold' })
    )(NumberInput),
    piToPhyiMaxThreshold: compose(
      withLabel('Patient In to Physician In Max'),
      withProps({ name: 'piToPhyiMaxThreshold' })
    )(NumberInput),
    closingCompleteToPatientOutMinThreshold: compose(
      withLabel('Closing Complete to Patient Out Min'),
      withProps({ name: 'closingCompleteToPatientOutMinThreshold' })
    )(NumberInput),
    closingCompleteToPatientOutMaxThreshold: compose(
      withLabel('Closing Complete to Patient Out Max'),
      withProps({ name: 'closingCompleteToPatientOutMaxThreshold' })
    )(NumberInput),
    samePhysicianORTurnover: compose(
      withLabel('OR Turnover with Same Physician'),
      withProps({ name: 'samePhysicianORTurnover' })
    )(ToogleInput),
  },
  render: props => (
    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: '1rem', marginBottom: '1rem' }}>
      {Object.keys(props).map(field => (
        <div
          key={field}
          style={{
            gridColumn:
              field.endsWith('Recipients') || field.endsWith('Period') || field.endsWith('Turnover')
                ? 'auto / span 2'
                : undefined,
          }}
        >
          {props[field]}
        </div>
      ))}
    </div>
  ),
})(ObjectInput);

export default AnalyticsConfigInputV2;
