import { useQuery } from '@apollo/client';
import { ZonedDateTime } from '@js-joda/core';
import { IconButton } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import HistoryIcon from '@material-ui/icons/History';
import { format } from 'date-fns';
import React, { Fragment, useMemo, useState } from 'react';
import { withRouter } from 'react-router';
import { orTabletProcedureSteps } from '../../../../graph/procedureSteps';
import { toPatientStatus } from '../../../pages/kiosk/tablet/util/procedureSteps';
import { isProcedureEventEditable, transform } from '../util/patientEvents';
import PatientEventEditButton from './PatientEventEditButton';
import PatientEventText, { getEventText } from './PatientEventText';
import PatientEventCreateButton from './PatientEventCreateButton';

const EventLogEntry = ({ event, eventText, timeStamp, performedBy, performedAt }) => {
  const classes = useStyles();

  return (
    <Box className={classes.info}>
      <Typography variant="body2" color="textSecondary">
        {timeStamp}
      </Typography>
      <PatientEventText event={event} eventText={eventText} />
      <Typography variant="body2" color="textSecondary">
        {`performed by ${performedBy || 'System'} at ${performedAt}`}
      </Typography>
    </Box>
  );
};

const PatientEvents = withRouter(({ location, events, patientId }) => {
  const transformed = useMemo(() => transform(events), [events]);
  const [history, setHistory] = useState(null);
  const { data } = useQuery(orTabletProcedureSteps);
  const procedureSteps = data?.orTabletProcedureSteps || [];
  const classes = useStyles();

  const performedAt = event =>
    format(ZonedDateTime.parse(event?.createdAt).toLocalDateTime().toString(), 'MM/DD/YYYY HH:mm:ss');

  const timeStamp = event =>
    format(ZonedDateTime.parse(event?.timestamp).toLocalDateTime().toString(), 'MM/DD/YYYY HH:mm:ss');

  return (
    <Box>
      <Box display="flex" alignItems="center" mb={2}>
        <Box flex={1}>
          <Typography variant="h3">Patient Journey</Typography>
        </Box>
        <PatientEventCreateButton
          patientId={patientId}
          date={format(
            events[0]?.createdAt ? ZonedDateTime.parse(events[0].createdAt).toLocalDateTime().toString() : new Date(),
            'YYYY-MM-DD'
          )}
        />
      </Box>

      <Box className={classes.collectionList}>
        {transformed.map(event => {
          const eventText = getEventText(
            event,
            procedureSteps.find(e => toPatientStatus(e.type) === event?.status)
          );
          const isEditable = isProcedureEventEditable(event);
          const hasHistory = (event?.history?.length || 0) > 0;

          return (
            <Fragment key={event?.id}>
              <Box className={classes.item}>
                <EventLogEntry
                  event={event}
                  eventText={eventText}
                  timeStamp={timeStamp(event)}
                  performedBy={event?.createdBy?.name}
                  performedAt={performedAt(event)}
                />

                <Box className={classes.actions}>
                  {hasHistory && (
                    <>
                      <Button
                        className={classes.desktopButton}
                        startIcon={<HistoryIcon />}
                        size="small"
                        variant="outlined"
                        onClick={() => setHistory(history === event?.id ? null : event?.id)}
                      >
                        {history && history === event?.id ? 'Hide' : 'Show'} History
                      </Button>
                      <IconButton
                        color="primary"
                        className={classes.mobileButton}
                        onClick={() => setHistory(history === event?.id ? null : event?.id)}
                      >
                        <HistoryIcon />
                      </IconButton>
                    </>
                  )}
                  {isEditable && <PatientEventEditButton patientId={patientId} event={event} eventText={eventText} />}
                </Box>
              </Box>

              {event?.id === history && (
                <>
                  {event?.history.map(event => {
                    const eventText = getEventText(
                      event,
                      procedureSteps.find(e => toPatientStatus(e.type) === event?.status)
                    );

                    return (
                      <EventLogEntry
                        key={event?.id}
                        event={event}
                        eventText={eventText}
                        timeStamp={timeStamp(event)}
                        performedBy={event?.createdBy?.name}
                        performedAt={performedAt(event)}
                      />
                    );
                  })}
                </>
              )}
              <Divider />
            </Fragment>
          );
        })}
      </Box>
    </Box>
  );
});

const useStyles = makeStyles(theme => ({
  collectionList: {
    display: 'flex',
    gap: theme.spacing(1),
    flexDirection: 'column',
  },
  item: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
  },
  info: {
    flex: 1,
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: theme.spacing(0.5),
    },
  },
  actions: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  mobileButton: {
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  desktopButton: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

export default PatientEvents;
