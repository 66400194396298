import { CssBaseline } from '@material-ui/core';
import { ThemeProvider as MUIThemeProvider } from '@material-ui/core/styles';
import {
  AccessibilityNew,
  AirlineSeatFlat,
  Description,
  EnhancedEncryption,
  Event,
  Healing,
  InsertChartOutlined,
  InvertColors,
  LocalHospital,
  MeetingRoom,
  MyLocation,
  Router,
  Schedule,
  Settings,
  ShoppingCart,
  Theaters,
  ThumbUp,
  Today,
  TrackChanges,
  Tune,
  Tv,
  VideoLabel,
  AccountBox,
} from '@material-ui/icons';
import { mdiFilter, mdiNeedle, mdiPackageVariantClosed } from '@mdi/js';
import Icon from '@mdi/react';
import compact from 'lodash/compact';
import get from 'lodash/fp/get';
import React, { Fragment } from 'react';
import { Redirect, Route, withRouter } from 'react-router';
import { Switch } from 'react-router-dom';
import { compose, mapProps, withProps } from 'recompose';
import { ThemeProvider } from 'styled-components';
import ChartSvg from '../../../../assets/images/icons/chart.svg';
import DevSvg from '../../../../assets/images/icons/developer_mode.svg';
import PeopleSvg from '../../../../assets/images/icons/people.svg';
import SmsSvg from '../../../../assets/images/icons/sms.svg';
import TitleSvg from '../../../../assets/images/icons/title.svg';
import UsersSvg from '../../../../assets/images/icons/users.svg';
import ThemeBasedCSS from '../../../../components/ThemeBasedCSS';
import { Context as PatientNameFormatContext } from '../../../../contexts/PatientNameFormat';
import { withScope } from '../../../../contexts/ScopeContext';
import { muiThemeDark } from '../../../../muiTheme';
import activeAndPastProceduresSource from '../../../../omnisearch/sources/hospital/activeAndPastProcedures';
import scheduledProceduresSource from '../../../../omnisearch/sources/hospital/scheduledProcedures';
import Page from '../../../../se/components/Page';
import Sidebar from '../../../../se/components/Sidebar';
import { isDefinedAndNotNull } from '../../../../se/utilities/check';
import { withSession } from '../../../../state/Session';
import { dark as darkTheme } from '../../../../theme';
import Anesthesiologists from '../../../entities/anesthesiologist/Anesthesiologists';
import PatientChartFieldConfig from '../../../entities/config/PatientChartFieldConfig';
import ProcedureCancellationReasons from '../../../entities/config/ProcedureCancellationReasons';
import ProcedureDelayReasons from '../../../entities/config/ProcedureDelayReasons';
import StaffTitlesConfig from '../../../entities/config/StaffTitleConfig';
import { CovidScreeningSC } from '../../../entities/covidScreening/CovidScreeningSubmissions';
import Equipment from '../../../entities/equipment/Equipment';
import Feedback from '../../../entities/feedback/Feedback';
import IPFiltering from '../../../entities/ip-filtering/IPFiltering';
import Patients from '../../../entities/patient/ActiveAndPastPatients';
import Physicians from '../../../entities/physician/Physicians';
import ProcedureTypes from '../../../entities/procedure-type/ProcedureTypes';
import InTakeForms from '../../../entities/procedures/InTakeForms';
import ScheduledProcedures from '../../../entities/procedures/ScheduledProcedures';
import Rooms from '../../../entities/room/Rooms';
import ScheduledRequests from '../../../entities/scheduleRequests/ScheduledRequests';
import Screens from '../../../entities/screens/Screens';
import StaffMembers from '../../../entities/staff/StaffMembers';
import Users from '../../../entities/user/Users';
import Vendors from '../../../entities/vendor/Vendors';
import { OmniSearchProvider, OmniSearchSources } from '../../../OmniSearch';
import Configuration from '../../../pages/Configuration';
import Tracker from '../../../tracker';
import Analytics from '../../Analytics';
import Gateways from '../../gateway/Gateways';
import OpenPositionCounter from '../../kiosk/openPositions/OpenPositionCounter';
import OpenPositions from '../../kiosk/openPositions/OpenPositions';
import { MainContainer } from '../../MainContainer';
import MinewTestUI from '../../minew';
import ReplaySignal from '../../replay';
import RulesConfiguration from '../../rules/RulesConfiguration';
import SensorPage from '../../sensor/SensorPage';
import Tracking from '../../tracking/Tracking';
import Training from '../../training/Training';
import { unpackSessionObject } from '../../unpackSessionObject';
import ChangePassword from '../ChangePassword';
import Footer from './Footer';
import Header from './Header';

const getSystemScreenRoutes = (basePath, baseUrl, organizationId, search, modules) =>
  compact([
    {
      path: `/kiosk/${organizationId}/monitor`,
      to: `/kiosk/${organizationId}/monitor`,
      target: '_blank',
      label: 'Operations Screen',
      icon: <VideoLabel />,
    },

    {
      path: `/kiosk/${organizationId}/monitor-staff`,
      to: `/kiosk/${organizationId}/monitor-staff`,
      target: '_blank',
      label: 'Staff Monitor Screen',
      icon: <AccountBox />,
    },
    {
      path: `/kiosk/${organizationId}/schedule`,
      to: `/kiosk/${organizationId}/schedule`,
      label: 'OR Schedule Screen',
      icon: <Event />,
    },
    {
      path: `/kiosk/${organizationId}/waiting-room`,
      to: `/kiosk/${organizationId}/waiting-room`,
      target: '_blank',
      label: 'Waiting Room Screen',
      icon: <Tv />,
      hideOnMobile: true,
    },
    modules.blockSchedule
      ? {
          path: `/kiosk/${organizationId}/block-schedule`,
          to: `/kiosk/${organizationId}/block-schedule`,
          label: 'Block Schedule',
          icon: <Schedule />,
        }
      : undefined,
  ]);

const getAdminRoutes = (
  basePath,
  baseUrl,
  isSuperAdmin,
  isMonitoringEngineer,
  modules,
  organizationId,
  ospitekOrdersIntegrations
) =>
  [
    {
      separator: true,
    },
    {
      path: `${basePath}/patients`,
      to: `${baseUrl}/patients`,
      label: 'Patients',
      icon: <AccessibilityNew />,
      component: Patients,
    },
    !isMonitoringEngineer && {
      path: `${basePath}/analytics`,
      to: `${baseUrl}/analytics`,
      label: 'Analytics',
      icon: <InsertChartOutlined />,
      component: Analytics,
    },
    !isMonitoringEngineer && {
      path: `${basePath}/feedback`,
      to: `${baseUrl}/feedback`,
      label: 'Feedback',
      icon: <ThumbUp />,
      component: Feedback,
    },
    modules.covidScreening && !isMonitoringEngineer
      ? {
          path: `${basePath}/covid-screening`,
          to: `${baseUrl}/covid-screening`,
          label: 'Covid Screening',
          icon: 'coronavirus',
          component: CovidScreeningSC,
        }
      : undefined,
    modules.inTakeForms && !isMonitoringEngineer
      ? {
          path: `${basePath}/registration-packages`,
          to: `${baseUrl}/registration-packages`,
          label: 'Registration Packages',
          icon: <Description />,
          component: InTakeForms,
        }
      : undefined,
    modules.patientRegistration && !isMonitoringEngineer
      ? {
          path: `${basePath}/scheduled-patients`,
          to: `${baseUrl}/scheduled-patients`,
          label: 'PreOp Registration',
          icon: <Schedule />,
          component: ScheduledProcedures,
        }
      : undefined,
    modules.acceptsProcedureTransfer && !isMonitoringEngineer
      ? {
          path: `${basePath}/scheduled-requests`,
          to: `${baseUrl}/scheduled-requests`,
          label: 'Schedule requests',
          icon: <Today />,
          component: ScheduledRequests,
        }
      : undefined,
    modules.temperatureReporting
      ? {
          path: `${basePath}/sensors`,
          to: `${baseUrl}/sensors`,
          label: 'Temp & Humidity',
          icon: <InvertColors />,
          component: SensorPage,
        }
      : undefined,
    {
      separator: true,
    },
    {
      label: 'Staffing',
      icon: <img src={PeopleSvg} />,
      NotificationComponent: OpenPositionCounter,
      subPages: [
        ...(!isMonitoringEngineer
          ? [
              {
                path: `${basePath}/positions`,
                to: `${baseUrl}/positions`,
                label: 'Open Positions',
                NotificationComponent: OpenPositionCounter,
                icon: <AccessibilityNew />,
                component: OpenPositions,
              },
              {
                path: `/kiosk/${organizationId}/calendar`,
                to: `/kiosk/${organizationId}/calendar`,
                target: '_blank',
                label: 'Calendar',
                icon: <Event />,
              },
            ]
          : []),
        {
          path: `${basePath}/physicians`,
          to: `${baseUrl}/physicians`,
          label: 'Physicians',
          icon: <LocalHospital />,
          component: Physicians,
        },
        {
          path: `${basePath}/staff`,
          to: `${baseUrl}/staff`,
          label: 'Staff Members',
          icon: <EnhancedEncryption />,
          component: StaffMembers,
        },
        {
          path: `${basePath}/anesthesiologists`,
          to: `${baseUrl}/anesthesiologists`,
          label: 'Anesthesiologists',
          icon: <AirlineSeatFlat />,
          component: Anesthesiologists,
        },
        {
          path: `${basePath}/vendors`,
          to: `${baseUrl}/vendors`,
          label: 'Vendors',
          icon: <Icon path={mdiPackageVariantClosed} title="Vendors" size={1} color="#fff" />,
          component: Vendors,
        },
      ],
    },
    {
      separator: true,
    },
    {
      label: 'Configuration',
      icon: <Settings />,
      subPages: [
        (!!isSuperAdmin || !!isMonitoringEngineer) && {
          path: `${basePath}/rooms`,
          to: `${baseUrl}/rooms`,
          label: 'Rooms',
          icon: <MeetingRoom />,
          component: Rooms,
        },
        ...(!isMonitoringEngineer
          ? [
              {
                path: `${basePath}/procedure-types`,
                to: `${baseUrl}/procedure-types`,
                label: 'Procedure Types',
                icon: <Healing />,
                component: ProcedureTypes,
              },
              {
                path: `${basePath}/equipment`,
                to: `${baseUrl}/equipment`,
                label: 'Equipment',
                icon: <Icon path={mdiNeedle} size={'24px'} />,
                component: Equipment,
              },
              {
                path: `${basePath}/users`,
                to: `${baseUrl}/users`,
                label: 'Users',
                icon: <img src={UsersSvg} />,
                component: Users,
              },
              {
                path: `${basePath}/ip-filtering`,
                to: `${baseUrl}/ip-filtering`,
                label: 'IP Filtering',
                icon: <Icon path={mdiFilter} size={'24px'} />,
                component: IPFiltering,
              },
            ]
          : []),
        {
          path: `${basePath}/screens`,
          to: `${baseUrl}/screens`,
          label: 'Screen Management',
          icon: <Tv />,
          component: Screens,
        },
        !!isSuperAdmin && {
          path: `${basePath}/patient-chart-fields`,
          to: `${baseUrl}/patient-chart-fields`,
          label: 'Patient Chart Fields',
          icon: <img src={ChartSvg} />,
          component: PatientChartFieldConfig,
        },
        (!!isSuperAdmin || !!isMonitoringEngineer) && {
          path: `${basePath}/staff-titles`,
          to: `${baseUrl}/staff-titles`,
          label: 'Staff Titles',
          icon: <img src={TitleSvg} />,
          component: StaffTitlesConfig,
        },
        (!!isSuperAdmin || !!isMonitoringEngineer) && {
          path: `${basePath}/procedure-cancellation-reasons`,
          to: `${baseUrl}/procedure-cancellation-reasons`,
          label: 'Procedure Cancellation Reasons',
          icon: <img src={TitleSvg} />,
          component: ProcedureCancellationReasons,
        },
        (!!isSuperAdmin || !!isMonitoringEngineer) && {
          path: `${basePath}/procedure-delay-reasons`,
          to: `${baseUrl}/procedure-delay-reasons`,
          label: 'Procedure Delay Reasons',
          icon: <img src={TitleSvg} />,
          component: ProcedureDelayReasons,
        },
        ...(!isMonitoringEngineer
          ? [
              {
                path: `${basePath}/configuration`,
                to: `${baseUrl}/configuration`,
                label: 'Messaging',
                icon: <img src={SmsSvg} />,
                component: Configuration,
              },
            ]
          : []),
      ].filter(_ => !!_),
    },
    {
      separator: true,
    },
    modules.ospitekOrdersIntegrations
      ? {
          externalLink: ospitekOrdersIntegrations?.externalLink?.link,
          label: 'Orders',
          icon: <ShoppingCart />,
        }
      : undefined,
    {
      externalLink: 'https://isqbazmccrg.typeform.com/to/KXHRf1SS',
      label: 'Development Requests',
      icon: <img src={DevSvg} />,
    },
    isMonitoringEngineer && {
      path: `${basePath}/tracker`,
      to: `${baseUrl}/tracker`,
      label: 'Track Journey',
      icon: <MyLocation />,
      component: Tracker,
    },
    isMonitoringEngineer && {
      path: `${basePath}/gateways`,
      to: `${baseUrl}/gateways`,
      label: 'Gateways',
      icon: <Router />,
      component: Gateways,
    },
    !isSuperAdmin &&
      !isMonitoringEngineer && {
        path: `${basePath}/training`,
        to: `${baseUrl}/training`,
        label: 'Video Tutorials',
        icon: <Theaters />,
        component: Training,
      },
    isMonitoringEngineer && {
      path: `${basePath}/rules`,
      to: `${baseUrl}/rules`,
      label: 'Transition Rules',
      icon: <Tune />,
      component: RulesConfiguration,
    },
  ].filter(_ => !!_);

const getSuperAdminUserRoutes = (basePath, baseUrl) => [
  {
    separator: true,
  },
  {
    path: `${basePath}/tracking`,
    to: `${baseUrl}/tracking`,
    label: 'Tracking Insights',
    icon: <TrackChanges />,
    component: Tracking,
  },
  {
    path: `${basePath}/tracker`,
    to: `${baseUrl}/tracker`,
    label: 'Track Journey',
    icon: <MyLocation />,
    component: Tracker,
  },
  {
    path: `${basePath}/gateways`,
    to: `${baseUrl}/gateways`,
    label: 'Gateways',
    icon: <Router />,
    component: Gateways,
  },
  {
    path: `${basePath}/rules`,
    to: `${baseUrl}/rules`,
    label: 'Transition Rules',
    icon: <Tune />,
    component: RulesConfiguration,
  },
];

const getSwitchRoutes = (
  basePath,
  baseUrl,
  isSuperAdmin,
  isMonitoringEngineer,
  modules,
  organizationId,
  ospitekOrdersIntegrations
) => {
  const adminRoutes = getAdminRoutes(
    basePath,
    baseUrl,
    isSuperAdmin,
    isMonitoringEngineer,
    modules,
    organizationId,
    ospitekOrdersIntegrations
  );
  if (isSuperAdmin) {
    const superAdminRoutes = getSuperAdminUserRoutes(basePath, baseUrl, modules);
    return [...adminRoutes, ...superAdminRoutes];
  }

  return adminRoutes;
};

const getBasePathAndUrl = match => {
  if (!isDefinedAndNotNull(match) || !isDefinedAndNotNull(!match.path) || !isDefinedAndNotNull(!match.url)) {
    return ['', ''];
  }
  return [match.path === '/' ? '' : match.path, match.url === '/' ? '' : match.url];
};

const AdminSidebar = compose(
  withSession(unpackSessionObject),
  withRouter,
  withScope,
  withProps({
    Header,
    links: [],
    Footer,
  }),
  mapProps(props => {
    const { organization, match, isSuperAdmin, isMonitoringEngineer, search } = props;
    const organizationId =
      (isSuperAdmin ? match.params['organizationId'] : organization) || match.params.organizationId;
    const [basePath, baseUrl] = getBasePathAndUrl(match);

    return {
      isSuperAdmin,
      ...props,
      links: compact([
        ...getSystemScreenRoutes(
          basePath,
          baseUrl,
          organizationId,
          search,
          Object.assign(
            {},
            {
              covidScreening: !!props.scope?.hospital?.covidScreening,
            },
            get('scope.hospital.modules')(props)
          )
        ),
        ...getSwitchRoutes(
          basePath,
          baseUrl,
          isSuperAdmin,
          isMonitoringEngineer,
          Object.assign(
            {},
            {
              covidScreening: !!props.scope?.hospital?.covidScreening,
              ospitekOrdersIntegrations: !!props.scope?.hospital?.ospitekOrdersIntegrations?.externalLink?.enabled,
            },
            get('scope.hospital.modules')(props)
          ),
          organizationId,
          props.scope?.hospital?.ospitekOrdersIntegrations
        ),
      ]),
    };
  })
)(Sidebar);

const Homepage = props => {
  const [basePath, baseUrl] = getBasePathAndUrl(props.match);
  const modules = {
    patientRegistration: true,
    temperatureReporting: true,
    acceptsProcedureTransfer: true,
    covidScreening: Boolean(props.scope?.hospital?.covidScreening),
    inTakeForms: Boolean(props.scope?.hospital?.modules?.inTakeForms),
  };
  const switchRoutes = compact(
    getSwitchRoutes(basePath, baseUrl, props.isSuperAdmin, props.isMonitoringEngineer, modules)
  ).filter(_ => !_.separator);
  return (
    <ThemeProvider theme={darkTheme}>
      <MUIThemeProvider theme={muiThemeDark}>
        <style>
          {`body {
            background: ${muiThemeDark.palette.background.default};
          }`}
        </style>
        <CssBaseline />
        <PatientNameFormatContext.Provider
          value={props.isSuperAdmin ? 'PatientId' : props.patientNameFormat || 'FullName'}
        >
          <OmniSearchProvider>
            <OmniSearchSources sources={[activeAndPastProceduresSource, scheduledProceduresSource]} />
            <Fragment>
              <Page Sidebar={AdminSidebar}>
                <MainContainer switchControl>
                  <Switch>
                    <Route exact path={`${basePath}/`} render={() => <Redirect to={`${baseUrl}/analytics`} />} />
                    {switchRoutes.map(({ path, component, subPages }, i) => [
                      path ? <Route key={i} path={path} component={component} /> : null,
                      ...(subPages
                        ? subPages.map(subPage => (
                            <Route
                              key={path ? `${path}${subPage.path}` : subPage.path}
                              path={path ? `${path}${subPage.path}` : subPage.path}
                              component={subPage.component}
                            />
                          ))
                        : []),
                    ])}
                    <Route path="/change-password" component={ChangePassword} />
                    {props.isSuperAdmin && <Route path={`${basePath}/tools/minew`} component={MinewTestUI} />}
                    {props.isSuperAdmin && <Route path={`${basePath}/tools/replay`} component={ReplaySignal} />}
                    <Route
                      render={() => (
                        <Redirect to={`${baseUrl}/${!props.isMonitoringEngineer ? 'analytics' : 'patients'}`} />
                      )}
                    />
                  </Switch>
                </MainContainer>
              </Page>
              <ThemeBasedCSS />
            </Fragment>
          </OmniSearchProvider>
        </PatientNameFormatContext.Provider>
      </MUIThemeProvider>
    </ThemeProvider>
  );
};

export default compose(
  withSession(unpackSessionObject),
  withRouter,
  withSession(get('session.account.metadata')),
  withScope
)(Homepage);
