import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme as withStyledTheme } from 'styled-components';
import Select from 'react-dropdown-select';
import { withTheme } from '../../theme';
import { withProps } from 'recompose';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DragHandle } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';

// Utility to move an item in an array
function arrayMove<T>(array: T[], from: number, to: number): T[] {
  const newArray = array.slice();
  const [movedItem] = newArray.splice(from, 1);
  newArray.splice(to, 0, movedItem);
  return newArray;
}

const StyledItem = styled.div<{ disabled?: boolean }>`
  background: ${withTheme(theme => theme.backgroundColor.string())};
  line-height: ${withTheme(theme => theme.lineHeight.xl)};
  padding-left: ${withTheme(theme => theme.input.padding)};
  cursor: pointer;
  display: flex;
  align-items: center;
  > div {
    display: flex;
    align-items: center;
  }
  input {
    margin-right: 1em;
  }
  :hover {
    background: ${({ theme }) => theme.backgroundColor.darken(0.05).string()};
  }
  opacity: ${({ disabled }) => (disabled ? 0.85 : 1)};
`;

const customStyles = (theme: any) => ({
  padding: 'calc(1rem * 0.75 - 0.125rem)',
  border: `2px solid ${theme.border.color.default.string()}`,
  cursor: 'pointer',
});

const ContentLabel = ({ props, state, label }: { props: any; state: any; label: string }) => (
  <span>
    {label || 'Configure Columns'} {`(${state.values.length} of ${props.options.length} columns)`}
  </span>
);

/**
 * DraggableItem renders each column in the dropdown with a drag handle and a checkbox.
 * The checkbox reflects the column's "visible" flag.
 */
type DraggableItemProps = {
  option: any;
  isDisabled: boolean;
  methods: any;
  valueField: string;
  labelField: string;
};

const DraggableItem: React.FC<DraggableItemProps> = ({ option, isDisabled, methods, valueField, labelField }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: option[valueField],
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
    display: 'flex',
    alignItems: 'center',
  };
  return (
    <StyledItem ref={setNodeRef} style={style} disabled={isDisabled}>
      <div {...attributes} {...listeners} style={{ marginRight: '0.5em', cursor: 'grab' }} aria-label="Drag handle">
        <DragHandle />
      </div>
      <input
        type="checkbox"
        onChange={e =>
          isDisabled ? {} : methods.isSelected(option) ? methods.removeItem(e, option, false) : methods.addItem(option)
        }
        checked={methods.isSelected(option)}
      />
      <span>{option[labelField]}</span>
    </StyledItem>
  );
};

/**
 * OptionList renders the dropdown list that allows reordering and toggling column visibility.
 * It operates on the full list of column settings.
 */
type OptionListProps = {
  props: any;
  state: any;
  methods: any;
  buttonRef: React.RefObject<HTMLButtonElement>;
};

const OptionList: React.FC<OptionListProps> = ({ props, state, methods, buttonRef }) => {
  const valueField = props.valueField || 'title';
  const labelField = props.labelField || 'title';
  const dropdownRef = useRef<HTMLDivElement>(null);

  const classes = useStyles();

  // We assume state.values holds the full ordered list of column settings.
  const [orderedOptions, setOrderedOptions] = useState<any[]>(() => {
    // Start with columns from storage (state.values) to preserve their order
    const result = [...state.values];

    // Add any columns from props.options that aren't in storage
    props.options.forEach(option => {
      if (!state.values.find(v => v[valueField] === option[valueField])) {
        result.push({ ...option, visible: true });
      }
    });

    return result;
  });

  // Update the local ordered options when either props.options or state.values changes
  useEffect(() => {
    // Start with columns from storage (state.values) to preserve their order
    const result = [...state.values];

    // Add any columns from props.options that aren't in storage
    props.options.forEach(option => {
      if (!state.values.find(v => v[valueField] === option[valueField])) {
        result.push({ ...option, visible: true });
      }
    });

    setOrderedOptions(result);
  }, [props.options, state.values, valueField]);

  /**
   * Handles drag end event to reorder the full list of columns.
   */
  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = orderedOptions.findIndex(item => item[valueField] === active.id);
      const newIndex = orderedOptions.findIndex(item => item[valueField] === over.id);

      // Create new ordered array
      const newOrdered = arrayMove(orderedOptions, oldIndex, newIndex);

      // Update both local state and parent
      setOrderedOptions(newOrdered);
      methods.onChange(newOrdered);
    }
  };

  /**
   * Toggles a column's visibility on.
   */
  const handleAddItem = (option: any) => {
    // When adding visibility, we need to ensure it's stored
    const newOrdered = orderedOptions.map(item =>
      item[valueField] === option[valueField] ? { ...item, visible: true } : item
    );
    methods.onChange(newOrdered);
  };

  /**
   * Toggles a column's visibility off.
   */
  const handleRemoveItem = (e: any, option: any) => {
    // When removing visibility, we need to ensure it's stored with visible: false
    const newOrdered = orderedOptions.map(item =>
      item[valueField] === option[valueField] ? { ...item, visible: false } : item
    );
    methods.onChange(newOrdered);
  };

  // Override default methods with our custom handlers.
  const customMethods = {
    ...methods,
    addItem: handleAddItem,
    removeItem: handleRemoveItem,
    isSelected: (option: any) => {
      // Check if the option is in state.values first
      const storedValue = state.values.find((v: any) => v[valueField] === option[valueField]);
      return storedValue ? storedValue.visible : true;
    },
  };

  // Close the dropdown when clicking outside.
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        state.dropdown &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        methods.closeDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [state.dropdown, methods.closeDropdown, buttonRef]);

  return (
    <div className={classes.item} ref={dropdownRef}>
      <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={orderedOptions.map(item => item[valueField])} strategy={verticalListSortingStrategy}>
          {orderedOptions.map(option => {
            const isSelected = option.visible;
            const isDisabled = option.disabled && isSelected && orderedOptions.filter(x => x.visible).length === 1;
            return (
              <DraggableItem
                key={option[valueField]}
                option={option}
                isDisabled={isDisabled}
                methods={customMethods}
                valueField={valueField}
                labelField={labelField}
              />
            );
          })}
        </SortableContext>
      </DndContext>
    </div>
  );
};

const ColumnSelect = withStyledTheme(
  ({
    theme,
    options,
    values,
    onChange,
    label,
    labelField = 'title',
    valueField = 'title',
    keepOpen,
    onClose,
    buttonRef,
  }: any) => (
    <Select
      multi
      options={options}
      values={values}
      labelField={labelField}
      valueField={valueField}
      style={customStyles(theme)}
      contentRenderer={withProps({ label })(ContentLabel)}
      dropdownRenderer={props => (
        <OptionList
          props={props.props}
          state={props.state}
          methods={{
            ...props.methods,
            onChange,
            closeDropdown: () => {
              onClose();
            },
          }}
          buttonRef={buttonRef}
        />
      )}
      onChange={onChange}
      dropdownHandle={false}
      keepOpen={keepOpen}
    />
  )
);

ColumnSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      visible: PropTypes.bool,
    })
  ).isRequired,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      visible: PropTypes.bool,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  buttonRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
};

const useStyles = makeStyles(theme => ({
  item: {
    overflow: 'auto',
    minHeight: 100,
    maxHeight: 600,
    backgroundColor: theme.palette.background.default,
  },
}));

export default ColumnSelect;
