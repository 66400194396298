import {
  AirlineSeatFlat,
  ArrowForward,
  Chat,
  DirectionsWalk,
  Gesture,
  Help,
  Hotel,
  HowToReg,
  Note,
  PersonAdd,
  TimeToLeave,
  Undo,
  LocalHospital,
} from '@material-ui/icons';
import React from 'react';

const getIcon = event => {
  switch (event?.type) {
    case 'Created':
      return <PersonAdd style={{ fontSize: '1.1em' }} />;
    case 'ReadyForAnesthesia':
      return <LocalHospital style={{ fontSize: '1.1em' }} />;
    case 'ReadyForSurgeon':
      return <HowToReg style={{ fontSize: '1.1em' }} />;
    case 'BecameReady':
    case 'BecameReadyInPACU':
    case 'BecameReadyInPREP':
    case 'FamilyReady':
      return <AirlineSeatFlat style={{ fontSize: '1.1em' }} />;
    case 'NotReadyInPREP':
    case 'NotReadyInPACU':
    case 'NotReadyForAnesthesia':
      return <Undo style={{ fontSize: '1.1em' }} />;
    case 'NotReadyForSurgeon':
      return <Undo style={{ fontSize: '1.1em' }} />;
    case 'Entered':
    case 'CleaningCompleted':
    case 'BlockNerve':
    case 'Exited':
      return <ArrowForward style={{ fontSize: '1.1em' }} />;
    case 'ProcedureStatusSet':
      if (event?.status?.toUpperCase() === 'READY') {
        return <Chat style={{ fontSize: '1.1em' }} />;
      }
      return <ArrowForward style={{ fontSize: '1.1em' }} />;
    case 'Discharged':
      return <DirectionsWalk style={{ fontSize: '1.1em' }} />;
    case 'FamilyReadyPACU':
      return <TimeToLeave style={{ fontSize: '1.1em' }} />;
    case 'MessageDelivery@FamilyTrackingInvit':
      return <Chat style={{ fontSize: '1.1em' }} />;
    case 'ReadyForSurgeonNotify':
      return <Chat style={{ fontSize: '1.1em' }} />;
    case 'ReadyForAnesthesiologistNotify':
    case 'MessageDelivery@FamilyTrackingInvite':
      return <Chat style={{ fontSize: '1.1em' }} />;
    case 'NoteChange':
      return <Note style={{ fontSize: '1.1em' }} />;
    case 'CaretakerConsentChange':
      return <Gesture style={{ fontSize: '1.1em' }} />;
    case 'BedChange':
      return <Hotel style={{ fontSize: '1.1em' }} />;
    case 'Unknown':
      return <Help fontSize={'small'} />;
    default:
      return null;
  }
};

const PatientEventIcon = ({ event }) => getIcon(event);

export default PatientEventIcon;
