import React, { Children, createContext, useContext, useEffect, useState } from 'react';
import isObject from 'lodash/isObject';

import config from '../config';
import { isString } from '@/se/utilities/check';
import { getNestedValue } from '@/se/utilities/data/object';
import { getStoredSession, removeStoredSession, storeSession } from '@/storedSession';
import { SessionError } from './SessionError';
import { z } from 'zod';

export const SessionContext = createContext(undefined);

export const useSession = () => useContext(SessionContext);

const { Provider, Consumer } = SessionContext;

const verify = async () => {
  const session = getStoredSession();

  if (session) {
    const response = await fetch(`${config.apiURL}/identity/session`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${session.token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const json = await response.json();

    if (response.status !== 200) {
      return { ...session, valid: false };
    } else {
      storeSession(json);
      return { ...json, valid: true };
    }
  } else {
    return null;
  }
};

const createUsingEmailPassword = async (email, password) => {
  const response = await fetch(`${config.apiURL}/identity/session`, {
    method: 'POST',
    body: JSON.stringify({ email, password }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  const json = await response.json();

  if (response.status !== 201) {
    const error = getNestedValue('error', json);

    throw new SessionError(response.status, error);
  }

  storeSession(json);
  return { ...json, valid: true };
};

const createUsingEmailPasswordAndOneTimePassword = async (email, password, oneTimePassword) => {
  const response = await fetch(`${config.apiURL}/identity/session`, {
    method: 'POST',
    body: JSON.stringify({ email, password, oneTimePassword }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  const json = await response.json();

  if (response.status !== 201) {
    const error = getNestedValue('error', json);

    throw new SessionError(response.status, error);
  }

  storeSession(json);
  return { ...json, valid: true };
};

const createUsingAccessToken = async accessToken => {
  const response = await fetch(`${config.apiURL}/identity/session`, {
    method: 'POST',
    body: JSON.stringify({ accessToken }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  const json = await response.json();

  if (response.status !== 201) {
    const error = getNestedValue('error', json);
    throw new SessionError(response.status, error);
  }

  storeSession(json);
  return { ...json, valid: true };
};

const destroy = async () => {
  const session = getStoredSession();
  if (session) {
    const response = await fetch(`${config.apiURL}/identity/session`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${session.token}`,
      },
    });

    if (Math.floor(response.status / 100) * 100 !== 200) {
      throw new SessionError(response.status, `Got ${response.status} while trying to destroy session.`);
    }
  }

  removeStoredSession();
  return null;
};

const changePasswordWithCredentials = async (email, password, newPassword) => {
  const response = await fetch(`${config.apiURL}/identity/session`, {
    method: 'POST',
    body: JSON.stringify({ email, password, newPassword }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  const json = await response.json();

  if (response.status !== 201) {
    const error = getNestedValue('error', json);

    throw new SessionError(response.status, error);
  }

  storeSession(json);
  return { ...json, valid: true };
};

const verifyResetPasswordToken = async token => {
  const urlSearchParams = new URLSearchParams({ token: encodeURIComponent(token) });
  const response = await fetch(`${config.apiURL}/identity/reset-password?${urlSearchParams.toString()}`, {
    method: 'GET',
  });

  return response.status === 200 ? { valid: true } : { valid: false };
};

const resetPasswordWithToken = async (token, newPassword) => {
  const urlSearchParams = new URLSearchParams({ token: encodeURIComponent(token) });
  const response = await fetch(`${config.apiURL}/identity/reset-password?${urlSearchParams.toString()}`, {
    method: 'POST',
    body: JSON.stringify({ newPassword }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  const json = await response.json();

  if (response.status !== 200) {
    const error = getNestedValue('error', json);

    throw new SessionError(response.status, error);
  }

  storeSession(json);
  return { ...json, valid: true };
};

const resendConfirmationLinkEmail = async email => {
  await fetch(`${config.apiURL}/identity/confirmation`, {
    method: 'POST',
    body: JSON.stringify({ email }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  return null;
};

const sendPasswordResetLinkEmail = async email => {
  const response = await fetch(`${config.apiURL}/identity/forgot-password`, {
    method: 'POST',
    body: JSON.stringify({ email }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  const json = await response.json();

  if (response.status !== 200) {
    const error = getNestedValue('error', json);

    throw new SessionError(response.status, error);
  }

  return json;
};

export const SessionProvider = ({ children }) => {
  const [session, setSession] = useState(undefined);

  useEffect(() => {
    verify().then(setSession).catch(console.error);
  }, []);

  const logIn = async (...args) => createUsingEmailPassword(...args).then(setSession);
  const logInOTP = async (...args) => createUsingEmailPasswordAndOneTimePassword(...args).then(setSession);
  const logInUsingAccessToken = async (...args) => createUsingAccessToken(...args).then(setSession);
  const logInAfterSettingPassword = async (...args) => changePasswordWithCredentials(...args).then(setSession);
  const logInAfterResetPasswordWithToken = async (...args) => resetPasswordWithToken(...args).then(setSession);

  const logOut = async (...args) => destroy(...args).then(setSession);
  const logOutPartially = () => {
    const session = getStoredSession();
    const nextSession = { ...session, valid: false };
    storeSession(nextSession);
    setSession(nextSession);
  };

  const [selectedMembershipId, setSelectedMembershipId] = useState();

  const selectMembership = membershipId => {
    setSelectedMembershipId(() => membershipId);

    if (session?.user?.id) {
      if (membershipId) {
        localStorage.setItem(`selectedMembership-${session.user.id}`, membershipId);
      } else {
        localStorage.removeItem(`selectedMembership-${session.user.id}`);
      }
    }
  };

  const value = {
    session: sessionWithMembership(
      session,
      (session?.user?.id ? localStorage.getItem(`selectedMembership-${session.user.id}`) : undefined) ||
        selectedMembershipId
    ),
    createUsingEmailPassword: logIn,
    createUsingEmailPasswordAndOneTimePassword: logInOTP,
    createUsingAccessToken: logInUsingAccessToken,
    changePasswordWithCredentials: logInAfterSettingPassword,
    resendConfirmationLinkEmail: resendConfirmationLinkEmail,
    sendPasswordResetLinkEmail,
    verifyResetPasswordToken,
    resetPasswordWithToken: logInAfterResetPasswordWithToken,
    destroy: logOut,
    destroyPartially: logOutPartially,
    selectMembership,
  };

  return <Provider value={value}>{Children.only(children)}</Provider>;
};

export const withSession = selector => Component => props => (
  <Consumer>{session => <Component {...props} {...selector(session)} />}</Consumer>
);

export const isReady = session => session === null || (isObject(session) && isString(session.token));

export const getVerificationError = session => (isObject(session) ? session.error : undefined);

const sessionWithMembership = (session, membershipId) => {
  if (!session) {
    return session;
  }

  let membership;

  if (membershipId) {
    membership = session?.user?.memberships.find(m => String(m.id) === String(membershipId));
  }

  if (!membership) {
    membership = session?.membership;
  }

  if (!membership && !isGroupAdmin(session?.user)) {
    membership = session?.user?.memberships?.[0];
  }

  return { ...session, membership };
};

export const isGroupAdmin = user => {
  const parsedMetadata = z.array(z.string()).safeParse(user?.metadata);

  return parsedMetadata.success && parsedMetadata.data.indexOf('GroupAdmin') >= 0;
};
