import React from 'react';
import { makePanels } from './Monitor';

const RoomPanelSlots = ({
  room,
  hospitalId,
  carouselSize,
  panelNumber,
  imageEmpty,
  hasPreOpPriorityModule,
  hasNoteModule,
  messagingView,
  screenType,
  staffMode,
}) => (
  <>
    {makePanels(
      room,
      carouselSize,
      panelNumber,
      hospitalId,
      imageEmpty,
      hasPreOpPriorityModule,
      hasNoteModule,
      messagingView,
      screenType,
      staffMode
    )}
  </>
);

export default RoomPanelSlots;
