import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';

interface TroubleshootingDialogProps {
  openDialog: boolean;
  handleCloseDialog: () => void;
  issueList: any[];
  issue: string;
  handleIssueChange: (event: React.ChangeEvent<{ name?: string; value: unknown }>, child: React.ReactNode) => void;
  setOtherIssue: (value: string) => void;
  sending: boolean;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
}

const TroubleshootingDialog = ({
  openDialog,
  handleCloseDialog,
  issueList,
  issue,
  handleIssueChange,
  setOtherIssue,
  sending,
  handleSubmit,
}: TroubleshootingDialogProps) => (
  <Dialog open={openDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm">
    <DialogTitle id="form-dialog-title">Submit a request</DialogTitle>
    <DialogContent>
      <Box component="form" onSubmit={handleSubmit}>
        <Box mb={2}>
          <FormControl fullWidth variant="filled" placeholder="Select an issue">
            <InputLabel id="demo-simple-select-label">Issue</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={issue}
              onChange={handleIssueChange}
            >
              {issueList.map(issue => (
                <MenuItem value={issue.value}>{issue.label}</MenuItem>
              ))}
              <MenuItem value="other">Other</MenuItem>
            </Select>
          </FormControl>
          {issue === 'other' && (
            <Box mt={1}>
              <TextField
                multiline
                minRows={4}
                variant="filled"
                autoFocus
                margin="dense"
                id="issue"
                label="What issue are you experiencing?"
                type="text"
                onChange={e => setOtherIssue(e.target.value)}
                placeholder="Briefly describe the issue you are experiencing"
                fullWidth
              />
            </Box>
          )}
        </Box>

        <Button variant="contained" color="secondary" type="submit" disabled={sending}>
          {sending ? 'Sending…' : 'Submit'}
        </Button>
      </Box>
    </DialogContent>
  </Dialog>
);

export default TroubleshootingDialog;
