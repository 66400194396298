import React, { Fragment } from 'react';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import styled from 'styled-components';
import { TruncatedText } from '../../../se/components/typography';
import { format, isBefore } from 'date-fns';
import {
  ResendEmail,
  ResendSms,
  ResendEmailIntake,
  ResendSmsIntake,
  ResendEmailInstructions,
  ResendSmsInstructions,
} from '../patient/columns';
import {
  resendInstructionInvitationEmail,
  resendInstructionInvitationSMS,
  resendInTakeFormInvitationEmail,
  resendInTakeFormInvitationSMS,
  resendQuestionnaireInvitationEmail,
  resendQuestionnaireInvitationSMS,
} from '../../../graph/procedures';
import { eventTypes, intakeStatusLabels } from './ProcedureEventLog';
import { sortAge, sortDate, sortNumber, sortString } from '../../../util/sort';
import { formatPhoneNumber, processPhoneNumbersString } from '../../../se/components/inputs/PhoneInput';
import InstructionsAccepted from './instructions/InstructionsAccepted';
import { emailInvitationCount, getLogEvents, smsInvitationCount } from './events';
import isFinite from 'lodash/isFinite';
import round from 'lodash/round';
import ExpandableText from '../../core/ExpandableText';
import { first } from 'lodash';
import { Box } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Chip from '@material-ui/core/Chip';
import CheckIcon from '@material-ui/icons/Check';
import clsx from 'clsx';
import color from 'color';
import { ZonedDateTime } from '@js-joda/core';
import PreferredLanguage from './PreferredLanguage';
import { PreOpPatientChatDialog } from '../../chat/ChatDialog';
import ChatButton from '../../chat/ChatButton';
import secretValue from '../../../util/secretValue';
import { useQuery } from '@apollo/client';
import { getNotificationTemplate } from '../../../graph/notificationTemplates';
import { getInstructionLastSent } from './instructions/SurgeryInstructions';
import isNil from 'lodash/fp/isNil';
import { SendingSetting } from '../notificationTemplate/NotificationTemplates';
import { calculateBMIStr } from '../../../questionnaire/toBMI';
import { useTheme } from '@material-ui/core/styles';

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const useStyles = makeStyles(theme => ({
  fulfilmentLow: {
    backgroundColor: theme.palette.error.main,
  },
  baseLow: {
    backgroundColor: color(theme.palette.error.main).alpha(0.15).string(),
  },
  fulfilmentMedium: {
    backgroundColor: theme.palette.warning.main,
  },
  baseMedium: {
    backgroundColor: color(theme.palette.warning.main).alpha(0.15).string(),
  },
  fulfilmentHigh: {
    backgroundColor: theme.palette.primary.main,
  },
  baseHigh: {
    backgroundColor: color(theme.palette.primary.main).alpha(0.15).string(),
  },
}));

export const FormFulfillmentCell = ({ percentage, lowColor, middleColor, highColor, color }) => {
  const rounded = isFinite(percentage) ? round(percentage, 1) : 0;
  const classes = useStyles(color);

  return (
    <Box display="flex" alignItems="center" width="100%">
      {rounded >= 100 ? (
        <Chip
          size="small"
          icon={<CheckIcon />}
          color="primary"
          label="100%"
          classes={{
            colorPrimary: classes.colorPrimary, // class name, e.g. `classes-nesting-root-x`
          }}
        />
      ) : (
        <Fragment>
          <Box width="100%" mr={1}>
            <LinearProgress
              variant="determinate"
              value={rounded}
              classes={{
                barColorPrimary: clsx({
                  [classes.fulfilmentLow]: rounded <= 30,
                  [classes.fulfilmentMedium]: rounded > 30 && rounded <= 80,
                  [classes.fulfilmentHigh]: rounded > 80,
                }),
                colorPrimary: clsx({
                  [classes.baseLow]: rounded <= 30,
                  [classes.baseMedium]: rounded > 30 && rounded <= 80,
                  [classes.baseHigh]: rounded > 80,
                }),
              }}
            />
          </Box>
          <Box minWidth={35}>
            <Typography variant="body2" color="textSecondary">{`${rounded}%`}</Typography>
          </Box>
        </Fragment>
      )}
    </Box>
  );
};

const patientAgeColumn = {
  title: 'Age',
  lens: data => data.patientAge,
  Component: props => (
    <TruncatedText style={{ color: props.data > 65 ? 'red' : 'inherit' }}>{props.data}</TruncatedText>
  ),
  sortFunction: (l, r, a, _, c) => sortAge(l.patientAge, r.patientAge, c.type),
};

const patientBMIColumn = {
  title: 'BMI',
  lens: data =>
    calculateBMIStr(
      data?.entryQuestionnaire?.weightLb,
      data?.entryQuestionnaire?.heightFt,
      data?.entryQuestionnaire?.heightIn
    ),
  Component: props => (
    <TruncatedText style={{ color: props.data > 40 ? 'red' : 'inherit' }}>{props.data}</TruncatedText>
  ),
  sortFunction: (l, r) =>
    sortString(
      calculateBMIStr(
        l?.entryQuestionnaire?.weightLb,
        l?.entryQuestionnaire?.heightFt,
        l?.entryQuestionnaire?.heightIn
      ),
      calculateBMIStr(r?.entryQuestionnaire?.weightLb, r?.entryQuestionnaire?.heightFt, r?.entryQuestionnaire?.heightIn)
    ),
};

export const PatientNameColumn = ({ Text = TruncatedText, data }) => <Text>{data.name}</Text>;
export const PatientDOBColumn = ({ Text = TruncatedText, data }) => <Text>{data.name}</Text>;

export const idColumns = [
  {
    title: '#',
    lens: data => data.id,
    sortFunction: (l, r) => sortNumber(l.id, r.id),
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
  },
  {
    title: 'Patient ID',
    lens: data => data.patientHstId,
    sortFunction: (l, r) => sortNumber(l.id, r.id),
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
  },
  {
    title: 'Visit',
    lens: data => data.visit,
    sortFunction: (l, r) => sortNumber(l.visit, r.visit),
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
  },
  {
    title: 'Name',
    lens: data => ({ name: data.patientName, id: data.patientId || 'F' }),
    Component: PatientNameColumn,
    sortFunction: (l, r) => sortString(l.patientName, r.patientName),
  },
];
``;

export const baseColumns = [
  {
    title: 'Date of Service',
    lens: data => data.startTime,
    Component: props => (
      <TruncatedText>
        {format(ZonedDateTime.parse(props.data).toLocalDateTime().toString(), 'MM/DD/YYYY HH:mm')}
      </TruncatedText>
    ),
    sortFunction: (l, r) => sortDate(l.startTime, r.startTime),
  },
  {
    title: 'Duration',
    lens: data => data.duration,
    Component: props => <TruncatedText>{props.data} min</TruncatedText>,
  },
  {
    title: 'Date of Birth',
    lens: data => data?.patientDateOfBirth,
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
    sortFunction: (l, r) => sortDate(l.patientDateOfBirth, r.patientDateOfBirth),
  },
  patientAgeColumn,
  patientBMIColumn,
  {
    title: 'Physician',
    lens: data => data?.physician?.name || data.physicianNameHST,
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
  },
];

const anesthesiologistColumn = {
  title: 'Anesthesiologist',
  lens: data => data?.anesthesiologist?.name,
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

const vendorColumn = {
  title: 'Vendor',
  lens: data => data.vendorRepresentative,
  Component: props => (
    <>
      <TruncatedText>{props.data?.vendor?.name}</TruncatedText>
      {props.data?.implant && (
        <TruncatedText>
          Implant and Price: {props.data?.implant} ({props.data?.price}$)
        </TruncatedText>
      )}
      {props.data?.representative?.name && (
        <TruncatedText>
          Representative: {props.data?.representative?.name}
          {props.data?.representative?.phoneNumber
            ? ` (${formatPhoneNumber(
                processPhoneNumbersString(props.data?.representative?.phoneNumber).nationalNumbers
              )})`
            : ''}
        </TruncatedText>
      )}
    </>
  ),
};

const diagnosisColumn = {
  title: 'Diagnosis',
  span: 2,
  lens: data => data.diagnosis,
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

const patientAddress = {
  title: 'Address',
  span: 3,
  lens: data => data.patientAddress,
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

const equipmentColumn = {
  title: 'Equipment',
  lens: data => data.equipment,
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

const cptCodesColumn = {
  title: 'CPT Codes',
  lens: data => data.cptCodes,
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

const bilaterallyColumn = {
  title: 'Laterality',
  lens: data => data.bilaterally,
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

const visitTypeColumn = {
  title: 'Visit Type',
  lens: data => data.visitType,
  Component: props => <TruncatedText>{props?.data}</TruncatedText>,
};

const insuranceNameColumn = {
  title: 'Insurance And Policy',
  lens: data => data,
  Component: props => (
    <>
      <TruncatedText>{props.data?.insuranceName || '-'}</TruncatedText>
      {props.data?.policyName && <TruncatedText>Policy: {props.data?.policyName}</TruncatedText>}
    </>
  ),
};

const procedureTypeColumn = {
  title: 'Procedure Type',
  span: 2,
  lens: data => data.procedureType,
  style: { overflow: 'hidden', minWidth: '20em', maxWidth: '30em' },
  Component: props => <ExpandableText textStyle={{ whiteSpace: 'normal' }} text={props.data} />,
};

const preferredLanguageColumn = readOnly => ({
  title: 'Preferred Language',
  lens: data => ({
    procedureId: data.id,
    language: data.preferredLanguage,
  }),
  Component: ({ data }) => <PreferredLanguage {...data} readOnly={readOnly} />,
});

const PatientMobilePhoneNumber = props => {
  const isPhoneNumber = !!props?.data?.patientPhoneNumber;

  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
      <TruncatedText>{props?.data?.patientPhoneNumber || '-'}</TruncatedText>
      {isPhoneNumber && (
        <ChatButton
          hasUnreadMessages={props?.data?.patient?.patientThread?.hasUnreadMessages}
          chatComponent={PreOpPatientChatDialog}
          chatProps={{
            patient: props?.data?.patient,
          }}
        />
      )}
    </Box>
  );
};

export const Interpreter = ({ data }) => {
  const interpreterData = data?.entryQuestionnaire?.interpreter;
  let needsInterpreter = '';
  if (interpreterData) {
    needsInterpreter = 'Yes';
  } else if (interpreterData === false) {
    needsInterpreter = 'No';
  } else {
    needsInterpreter = '';
  }
  return <TruncatedText>{needsInterpreter}</TruncatedText>;
};

const patientMobilePhoneNumberColumn = {
  title: 'Patient Mobile Phone Number',
  lens: data => ({
    patientPhoneNumber:
      !isNil(data.patientCellPhone) && data.patientCellPhone !== secretValue
        ? formatPhoneNumber(processPhoneNumbersString(data.patientCellPhone).nationalNumbers)
        : data.patientCellPhone === secretValue
          ? secretValue
          : undefined,
    patientThread: data.patientThread,
    patient: {
      name: data?.patientName,
      patientThread: data?.patientThread,
      id: data?.patientId || 'F',
      procedureId: data?.id,
      initials: data?.patientInitials,
      phoneNumber: data?.patientCellPhone,
    },
  }),
  Component: PatientMobilePhoneNumber,
};

const patientMobileColumn = {
  title: 'Mobile',
  lens: data =>
    !isNil(data.patientCellPhone)
      ? formatPhoneNumber(processPhoneNumbersString(data.patientCellPhone).nationalNumbers)
      : undefined,
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

const patientHomePhoneNumberColumn = {
  title: 'Patient Home Phone Number',
  lens: data =>
    data.patientHomePhone !== null && data.patientHomePhone !== undefined && data.patientHomePhone !== secretValue
      ? formatPhoneNumber(processPhoneNumbersString(data.patientHomePhone).nationalNumbers)
      : data.patientHomePhone === secretValue
        ? secretValue
        : undefined,
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

const caretakerPhoneNumberColumn = {
  title: 'Caretaker Phone Number',
  lens: data =>
    data.patient?.notificationNumbers !== null &&
    data.patient?.notificationNumbers !== undefined &&
    data?.patient?.notificationNumbers !== secretValue
      ? formatPhoneNumber(processPhoneNumbersString(data.patient.notificationNumbers).nationalNumbers)
      : data?.patient?.notificationNumbers === secretValue
        ? secretValue
        : undefined,
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

const familyPhoneNumberColumn = {
  title: 'Family Phone Number',
  lens: data =>
    data.familyCellPhone !== null && data.familyCellPhone !== undefined && data.familyCellPhone !== secretValue
      ? formatPhoneNumber(processPhoneNumbersString(data.familyCellPhone).nationalNumbers)
      : data.familyCellPhone === secretValue
        ? secretValue
        : undefined,
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

const patientEmailAddressColumn = {
  title: 'Patient Email Address',
  lens: data => data.patientEmailAddress,
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

const patientViewEmailAddressColumn = {
  title: 'Email',
  lens: data => data.patientEmailAddress,
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

const formFulfillmentColumn = {
  title: 'Questionnaire %',
  lens: data => get(data, 'entryQuestionnaireProgress'),
  sortFunction: (l, r) => sortNumber(get(l, 'entryQuestionnaireProgress'), get(r, 'entryQuestionnaireProgress')),
  Component: props => <FormFulfillmentCell percentage={isFinite(props.data) ? Math.round(props.data * 100) : 0} />,
};

const inTakeFormFulfillmentColumn = {
  title: 'Registration Questionnaire %',
  lens: data => get(data, 'inTakeFormProgress'),
  sortFunction: (l, r) => sortNumber(get(l, 'inTakeFormProgress'), get(r, 'inTakeFormProgress')),
  Component: props => <FormFulfillmentCell percentage={isFinite(props.data) ? Math.round(props.data * 100) : 0} />,
};

export const InlineColumn = styled.div`
  display: flex;
  flex-direction: column;

  > * + * {
    margin-top: 0.5em;
  }
`;

const ResendActions =
  ({
    resendInvitationSMS,
    resendInvitationEmail,
    smsTooltip,
    emailTooltip,
    shouldWarnProvider,
    variablesProvider,
    invitationsProvider,
    emailInvitationsProvider,
    smsLabel,
    emailLabel,
    questionnaireInviteSMSCount,
    questionnaireInviteEmailCount,
    lastQuestionnaireInviteSMS,
    patientNameProvider,
    patientMobilePhoneNumberProvider,
    patientEmailAddressProvider,
  }) =>
  ({ data, preSubmit, smsEnabled, emailEnabled }) => {
    const invitations = invitationsProvider(data);
    const lastEvent = first(orderBy(invitations, 'timestamp', 'desc')) || lastQuestionnaireInviteSMS?.(data);
    const shouldWarn = shouldWarnProvider(data);
    const patientName = patientNameProvider(data);
    const patientMobilePhoneNumber = patientMobilePhoneNumberProvider(data);
    const patientEmailAddress = patientEmailAddressProvider(data);

    return data ? (
      <Actions
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <ResendSms
          disabled={
            !data.patientCellPhone ||
            isBefore(get(data, 'startTime') || get(data, 'consultationDateTime'), new Date()) ||
            !smsEnabled
          }
          disabledTooltip={
            !data.patientCellPhone
              ? 'No Patient Cell Phone'
              : isBefore(get(data, 'startTime') || get(data, 'consultationDateTime'), new Date())
                ? 'Procedure is completed'
                : 'Patient messaging has been turned off. Go to message configuration to enable it.'
          }
          mutation={resendInvitationSMS}
          variables={variablesProvider(data)}
          // options={{ refetchQueries: [{ query: procedures.item, variables: { id: data.id } }] }}
          tooltip={smsTooltip}
          timesSent={smsInvitationCount(invitations) || questionnaireInviteSMSCount?.(data)}
          status={get(lastEvent, 'status')}
          error={[get(lastEvent, 'content'), get(lastEvent, 'text')].filter(_ => !!_).join(' - ')}
          lastEventAt={get(lastEvent, 'timestamp')}
          shouldWarn={shouldWarn}
          label={smsLabel}
          preSubmit={preSubmit}
          patientName={patientName}
          patientMobilePhoneNumber={patientMobilePhoneNumber}
        />
        <ResendEmail
          disabled={
            !data.patientEmailAddress ||
            isBefore(get(data, 'startTime') || get(data, 'consultationDateTime'), new Date()) ||
            !emailEnabled
          }
          disabledTooltip={
            !data.patientEmailAddress
              ? 'No Patient Email Address'
              : isBefore(get(data, 'startTime') || get(data, 'consultationDateTime'), new Date())
                ? 'Procedure is completed'
                : 'Patient emailing has been turned off. Go to message configuration to enable it.'
          }
          mutation={resendInvitationEmail}
          variables={variablesProvider(data)}
          // options={{ refetchQueries: [{ query: procedures.item, variables: { id: data.id } }] }}
          tooltip={emailTooltip}
          timesSent={emailInvitationCount(emailInvitationsProvider(data)) || questionnaireInviteEmailCount?.(data)}
          shouldWarn={shouldWarn}
          label={emailLabel}
          preSubmit={preSubmit}
          patientName={patientName}
          patientEmailAddress={patientEmailAddress}
        />
      </Actions>
    ) : null;
  };

const ResendActionsIntake =
  ({
    resendInvitationSMS,
    resendInvitationEmail,
    smsTooltip,
    emailTooltip,
    shouldWarnProvider,
    variablesProvider,
    invitationsProvider,
    emailInvitationsProvider,
    smsLabel,
    emailLabel,
    questionnaireInviteSMSCount,
    questionnaireInviteEmailCount,
    lastQuestionnaireInviteSMS,
    patientNameProvider,
    patientMobilePhoneNumberProvider,
    patientEmailAddressProvider,
  }) =>
  ({ data, preSubmit, smsEnabled, emailEnabled }) => {
    const invitations = invitationsProvider(data);
    const lastEvent = first(orderBy(invitations, 'timestamp', 'desc')) || lastQuestionnaireInviteSMS?.(data);
    const shouldWarn = shouldWarnProvider(data);
    const patientName = patientNameProvider(data);
    const patientMobilePhoneNumber = patientMobilePhoneNumberProvider(data);
    const patientEmailAddress = patientEmailAddressProvider(data);

    return data ? (
      <Actions
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <ResendSmsIntake
          disabled={
            !data.patientCellPhone ||
            isBefore(get(data, 'startTime') || get(data, 'consultationDateTime'), new Date()) ||
            !smsEnabled
          }
          disabledTooltip={
            !data.patientCellPhone
              ? 'No Patient Cell Phone'
              : isBefore(get(data, 'startTime') || get(data, 'consultationDateTime'), new Date())
                ? 'Procedure is completed'
                : 'Patient messaging has been turned off. Go to message configuration to enable it.'
          }
          mutation={resendInvitationSMS}
          variables={variablesProvider(data)}
          // options={{ refetchQueries: [{ query: procedures.item, variables: { id: data.id } }] }}
          tooltip={smsTooltip}
          timesSent={smsInvitationCount(invitations) || questionnaireInviteSMSCount?.(data)}
          status={get(lastEvent, 'status')}
          error={[get(lastEvent, 'content'), get(lastEvent, 'text')].filter(_ => !!_).join(' - ')}
          lastEventAt={get(lastEvent, 'timestamp')}
          shouldWarn={shouldWarn}
          label={smsLabel}
          preSubmit={preSubmit}
          patientName={patientName}
          patientMobilePhoneNumber={patientMobilePhoneNumber}
        />
        <ResendEmailIntake
          disabled={
            !data.patientEmailAddress ||
            isBefore(get(data, 'startTime') || get(data, 'consultationDateTime'), new Date()) ||
            !emailEnabled
          }
          disabledTooltip={
            !data.patientEmailAddress
              ? 'No Patient Email Address'
              : isBefore(get(data, 'startTime') || get(data, 'consultationDateTime'), new Date())
                ? 'Procedure is completed'
                : 'Patient emailing has been turned off. Go to message configuration to enable it.'
          }
          mutation={resendInvitationEmail}
          variables={variablesProvider(data)}
          // options={{ refetchQueries: [{ query: procedures.item, variables: { id: data.id } }] }}
          tooltip={emailTooltip}
          timesSent={emailInvitationCount(emailInvitationsProvider(data)) || questionnaireInviteEmailCount?.(data)}
          shouldWarn={shouldWarn}
          label={emailLabel}
          preSubmit={preSubmit}
          patientName={patientName}
          patientEmailAddress={patientEmailAddress}
        />
      </Actions>
    ) : null;
  };

const ResendActionsInstructionsHelper =
  ({
    resendInvitationSMS,
    resendInvitationEmail,
    smsTooltip,
    emailTooltip,
    shouldWarnProvider,
    variablesProvider,
    invitationsProvider,
    emailInvitationsProvider,
    smsLabel,
    emailLabel,
    questionnaireInviteSMSCount,
    questionnaireInviteEmailCount,
    lastQuestionnaireInviteSMS,
    patientNameProvider,
    patientMobilePhoneNumberProvider,
    patientEmailAddressProvider,
  }) =>
  ({ data, preSubmit, smsEnabled, emailEnabled }) => {
    const invitations = invitationsProvider(data);
    const lastEvent = first(orderBy(invitations, 'timestamp', 'desc')) || lastQuestionnaireInviteSMS?.(data);
    const shouldWarn = shouldWarnProvider(data);
    const patientName = patientNameProvider(data);
    const patientMobilePhoneNumber = patientMobilePhoneNumberProvider(data);
    const patientEmailAddress = patientEmailAddressProvider(data);

    return data ? (
      <Actions
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <ResendSmsInstructions
          disabled={
            !data.patientCellPhone ||
            isBefore(get(data, 'startTime') || get(data, 'consultationDateTime'), new Date()) ||
            !smsEnabled
          }
          disabledTooltip={
            !data.patientCellPhone
              ? 'No Patient Cell Phone'
              : isBefore(get(data, 'startTime') || get(data, 'consultationDateTime'), new Date())
                ? 'Procedure is completed'
                : 'Patient messaging has been turned off. Go to message configuration to enable it.'
          }
          mutation={resendInvitationSMS}
          variables={variablesProvider(data)}
          // options={{ refetchQueries: [{ query: procedures.item, variables: { id: data.id } }] }}
          tooltip={smsTooltip}
          timesSent={smsInvitationCount(invitations) || questionnaireInviteSMSCount?.(data)}
          status={get(lastEvent, 'status')}
          error={[get(lastEvent, 'content'), get(lastEvent, 'text')].filter(_ => !!_).join(' - ')}
          lastEventAt={get(lastEvent, 'timestamp')}
          shouldWarn={shouldWarn}
          label={smsLabel}
          preSubmit={preSubmit}
          patientName={patientName}
          patientMobilePhoneNumber={patientMobilePhoneNumber}
        />
        <ResendEmailInstructions
          disabled={
            !data.patientEmailAddress ||
            isBefore(get(data, 'startTime') || get(data, 'consultationDateTime'), new Date()) ||
            !emailEnabled
          }
          disabledTooltip={
            !data.patientEmailAddress
              ? 'No Patient Email Address'
              : isBefore(get(data, 'startTime') || get(data, 'consultationDateTime'), new Date())
                ? 'Procedure is completed'
                : 'Patient emailing has been turned off. Go to message configuration to enable it.'
          }
          mutation={resendInvitationEmail}
          variables={variablesProvider(data)}
          // options={{ refetchQueries: [{ query: procedures.item, variables: { id: data.id } }] }}
          tooltip={emailTooltip}
          timesSent={emailInvitationCount(emailInvitationsProvider(data)) || questionnaireInviteEmailCount?.(data)}
          shouldWarn={shouldWarn}
          label={emailLabel}
          preSubmit={preSubmit}
          patientName={patientName}
          patientEmailAddress={patientEmailAddress}
        />
      </Actions>
    ) : null;
  };

export const ResendActionsQuestionnaireWrapper = ({ data }) => {
  const { data: smsPatientReadyNotification } = useQuery(getNotificationTemplate, {
    variables: { trigger: 'QuestionnaireInvite', channel: 'SMS' },
  });
  const smsNotificationData = smsPatientReadyNotification?.getNotificationTemplate[0] || null;

  const { data: emailPatientReadyNotification } = useQuery(getNotificationTemplate, {
    variables: { trigger: 'QuestionnaireInvite', channel: 'Email' },
  });
  const emailNotificationData = emailPatientReadyNotification?.getNotificationTemplate[0] || null;

  return (
    <ResendActionsQuestionnaire
      data={data}
      smsEnabled={smsNotificationData?.sendingSetting !== SendingSetting.Off}
      emailEnabled={emailNotificationData?.sendingSetting !== SendingSetting.Off}
    />
  );
};

export const ResendActionsInTakeWrapper = ({ data }) => {
  const { data: smsPatientReadyNotification } = useQuery(getNotificationTemplate, {
    variables: { trigger: 'IntakeFormInvite', channel: 'SMS' },
  });
  const smsNotificationData = smsPatientReadyNotification?.getNotificationTemplate[0] || null;

  const { data: emailPatientReadyNotification } = useQuery(getNotificationTemplate, {
    variables: { trigger: 'IntakeFormInvite', channel: 'Email' },
  });
  const emailNotificationData = emailPatientReadyNotification?.getNotificationTemplate[0] || null;

  return (
    <ResendActionsInTake
      data={data}
      smsEnabled={smsNotificationData?.sendingSetting !== SendingSetting.Off}
      emailEnabled={emailNotificationData?.sendingSetting !== SendingSetting.Off}
    />
  );
};

export const ResendActionsQuestionnaire = ResendActions({
  resendInvitationSMS: resendQuestionnaireInvitationSMS,
  resendInvitationEmail: resendQuestionnaireInvitationEmail,
  smsTooltip: 'This will resend the SMS containing a link to the patient questionnaire.',
  emailTooltip: 'This will resend the email containing a link to the patient questionnaire.',
  shouldWarnProvider: data =>
    (get(data, 'entryQuestionnaire.progress', 0) || get(data, 'entryQuestionnaireProgress', 0)) > 0.8,
  variablesProvider: data => ({ procedureId: data.id }),
  invitationsProvider: getLogEvents(eventTypes.MessageDeliveryQuestionnaire),
  emailInvitationsProvider: getLogEvents(eventTypes.QuestionnaireSent),
  questionnaireInviteSMSCount: data => data?.questionnaireInviteSMSCount || 0,
  questionnaireInviteEmailCount: data => data?.questionnaireInviteEmailCount || 0,
  lastQuestionnaireInviteSMS: data => data?.lastQuestionnaireInviteSMS || null,
  patientNameProvider: data => data?.patientName || '',
  patientMobilePhoneNumberProvider: data => data?.patientCellPhone || '',
  patientEmailAddressProvider: data => data?.patientEmailAddress || '',
});

export const ResendActionsInTake = ResendActionsIntake({
  resendInvitationSMS: resendInTakeFormInvitationSMS,
  resendInvitationEmail: resendInTakeFormInvitationEmail,
  smsTooltip: 'This will resend the SMS containing a link to the patient Registration Package.',
  emailTooltip: 'This will resend the email containing a link to the patient Registration Package.',
  shouldWarnProvider: data => (get(data, 'inTakeForm.progress', 0) || get(data, 'inTakeFormProgress', 0)) > 0.8,
  variablesProvider: data => ({ procedureId: data.id }),
  invitationsProvider: getLogEvents(eventTypes.MessageDeliveryInTakeForm),
  emailInvitationsProvider: getLogEvents(eventTypes.InTakeFormSent),
  questionnaireInviteSMSCount: data => data?.inTakeFormInviteSMSCount || 0,
  questionnaireInviteEmailCount: data => data?.inTakeFormInviteEmailCount || 0,
  lastQuestionnaireInviteSMS: data => data?.lastInTakeFormInviteSMS || null,
  patientNameProvider: data => data?.patientName || '',
  patientMobilePhoneNumberProvider: data => data?.patientCellPhone || '',
  patientEmailAddressProvider: data => data?.patientEmailAddress || '',
});

export const ResendActionsInstructions = ResendActionsInstructionsHelper({
  resendInvitationSMS: resendInstructionInvitationSMS,
  resendInvitationEmail: resendInstructionInvitationEmail,
  smsTooltip: 'This will resend the SMS containing a link to the patient questionnaire.',
  emailTooltip: 'This will resend the email containing a link to the patient questionnaire.',
  shouldWarnProvider: () => false,
  variablesProvider: data => ({ procedureId: data.id, templateType: data.templateType }),
  invitationsProvider: getLogEvents(eventTypes.MessageDeliveryInstructions),
  emailInvitationsProvider: getLogEvents(eventTypes.InstructionsSent),
  smsLabel: 'Send SMS Instructions',
  emailLabel: 'Send Email Instructions',
  patientNameProvider: data => data?.patientName || '',
  patientMobilePhoneNumberProvider: data => data?.patientCellPhone || '',
  patientEmailAddressProvider: data => data?.patientEmailAddress || '',
});

const questionnaireInviteAtColumn = {
  title: 'Last Invite',
  lens: data => get(data, 'questionnaireInviteSentAt'),
  Component: ({ data }) => (
    <TruncatedText>
      {data ? format(ZonedDateTime.parse(data).toLocalDateTime().toString(), 'MM/DD/YYYY HH:mm') : 'Never'}
    </TruncatedText>
  ),
  sortFunction: (l, r) => sortDate(get(l, 'questionnaireInviteSentAt'), get(r, 'questionnaireInviteSentAt')),
};

const inTakeInviteAtColumn = {
  title: 'Last Invite',
  lens: data => get(data, 'inTakeFormInviteSentAt'),
  Component: ({ data }) => (
    <TruncatedText>
      {data ? format(ZonedDateTime.parse(data).toLocalDateTime().toString(), 'MM/DD/YYYY HH:mm') : 'Never'}
    </TruncatedText>
  ),
  sortFunction: (l, r) => sortDate(get(l, 'inTakeFormInviteSentAt'), get(r, 'inTakeFormInviteSentAt')),
};

export const statuses = {
  preopCompleted: 'PreOP Completed',
  infoPending: 'Info Pending',
};

const statusColumn = {
  title: 'Status',
  lens: data => get(data, 'status'),
  Component: ({ data }) => <TruncatedText>{statuses[data] || data || '-'}</TruncatedText>,
};

const docsLastPrintedAtColumn = {
  title: 'Docs Printed',
  lens: data => get(data, 'docsLastPrintedAt'),
  Component: ({ data }) => {
    const theme = useTheme();
    return data ? (
      <span style={{ color: theme.palette.success.main, display: 'flex', alignItems: 'center' }}>
        <CheckIcon style={{ marginTop: '-0.2em', marginRight: '0.2em' }} /> Printed
      </span>
    ) : (
      'Never'
    );
  },
};

const intakeStatusColumn = {
  title: 'Status',
  lens: data => data?.intakeStatus || '',
  Component: ({ data }) => <TruncatedText>{intakeStatusLabels[data] || '-'}</TruncatedText>,
};

const anesthesiaReviewedColumn = {
  title: 'Anesthesia Reviewed',
  lens: data => get(data, 'anesthesiaReviewed'),
  Component: ({ data }) => <TruncatedText>{data ? 'Yes' : 'No'}</TruncatedText>,
};

const interpreterColumn = {
  title: 'Needs Interpreter',
  lens: data => data,
  Component: Interpreter,
};

const actionsColumn = {
  title: 'Questionnaire Invite',
  lens: data => data,
  Component: ResendActionsQuestionnaireWrapper,
};

const inTakeActionsColumn = {
  title: 'Registration Package Invite',
  lens: data => data,
  Component: ResendActionsInTakeWrapper,
};

const providerIdColumn = {
  title: 'Patient ID',
  lens: data => data.patientHstId,
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

const instructionsAcceptedColumn = {
  title: 'Instructions Accepted',
  lens: data => data,
  Component: InstructionsAccepted,
  sortFunction: (l, r) => sortString(l.instructionsAccepted, r.instructionsAccepted),
};

const instructionsSentColumn = {
  title: 'Instructions Sent',
  lens: data => data,
  Component: ({ data }) => {
    const sortedLastTwoSentAt = getInstructionLastSent(data);
    const invite = sortedLastTwoSentAt?.[0];
    return (
      <TruncatedText>
        {invite ? format(ZonedDateTime.parse(invite).toLocalDateTime().toString(), 'MM/DD/YYYY HH:mm') : 'Never'}
      </TruncatedText>
    );
  },
  sortFunction: (l, r) => sortDate(get(l, 'instructionsInviteSentAt'), get(r, 'instructionsInviteSentAt')),
};

const filesUploadedColumn = {
  title: 'Files Uploaded',
  lens: data => data.filesUploaded,
  Component: ({ data }) => <TruncatedText>{data ? `${data} file${data === 1 ? '' : 's'}` : '-'}</TruncatedText>,
  sortFunction: (l, r) => sortDate(get(l, 'filesUploaded'), get(r, 'filesUploaded')),
  hidden: false,
};

export const listColumns = [
  ...idColumns,
  preferredLanguageColumn(true),
  interpreterColumn,
  ...baseColumns,
  procedureTypeColumn,
  patientViewEmailAddressColumn,
  patientMobileColumn,
  formFulfillmentColumn,
  actionsColumn,
  questionnaireInviteAtColumn,
  anesthesiaReviewedColumn,
  instructionsSentColumn,
  instructionsAcceptedColumn,
  statusColumn,
  docsLastPrintedAtColumn,
  filesUploadedColumn,
];

export const inTakeFormListColumns = [
  ...idColumns,
  preferredLanguageColumn(true),
  ...baseColumns,
  procedureTypeColumn,
  patientViewEmailAddressColumn,
  patientMobileColumn,
  inTakeFormFulfillmentColumn,
  inTakeActionsColumn,
  inTakeInviteAtColumn,
  intakeStatusColumn,
];

export const uploadedProcedureColumns = [
  providerIdColumn,
  ...baseColumns,
  anesthesiologistColumn,
  patientEmailAddressColumn,
  patientMobilePhoneNumberColumn,
  patientHomePhoneNumberColumn,
  procedureTypeColumn,
  preferredLanguageColumn(false),
];

export const createdProcedureColumns = [
  providerIdColumn,
  diagnosisColumn,
  ...baseColumns,
  anesthesiologistColumn,
  cptCodesColumn,
  procedureTypeColumn,
  visitTypeColumn,
  bilaterallyColumn,
  equipmentColumn,
  vendorColumn,
  patientEmailAddressColumn,
  patientMobilePhoneNumberColumn,
  patientHomePhoneNumberColumn,
  insuranceNameColumn,
  preferredLanguageColumn(false),
  patientAddress,
];

export const inTakeFormColumns = [
  providerIdColumn,
  ...baseColumns,
  anesthesiologistColumn,
  procedureTypeColumn,
  visitTypeColumn,
  patientEmailAddressColumn,
  patientMobilePhoneNumberColumn,
  patientHomePhoneNumberColumn,
  preferredLanguageColumn(false),
];
