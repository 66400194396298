import React, { useState } from 'react';
import { Box, Button, Menu, MenuItem, Typography } from '@material-ui/core';
import { useScope } from '@/hooks/useScope';
import useStyles from './Troubleshooting.styles';
import TroubleshootingDialog from '@/components/pages/kiosk/tablet/Troubleshooting/TroubleshootingDialog';
import TroubleshootingSnackbar from '@/components/pages/kiosk/tablet/Troubleshooting/TroubleshootingSnackbar';
import { TroubleshootingAlertProps } from '@/components/pages/kiosk/tablet/Troubleshooting/types';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import { HelpOutlined } from '@material-ui/icons';
import { gql, useMutation } from '@apollo/client';

const refreshScreen = () => {
  window.location.reload();
};

const issueListOptions = [
  {
    label: 'iPad in OR showing incorrect patient information',
    value: 'ipad_in_or_showing_incorrect_patient_information',
  },
  { label: 'iPad in OR not displaying patient details', value: 'ipad_in_or_not_displaying_patient_details' },
  { label: 'Patient location is incorrect on iPad or TV', value: 'patient_location_is_incorrect_on_ipad_or_tv' },
  {
    label: 'iPad in OR showing a blank screen or stuck on loading',
    value: 'ipad_in_or_showing_a_blank_screen_or_stuck_on_loading',
  },
  {
    label: 'TV in OR not functioning, stuck on loading screen',
    value: 'tv_in_or_not_functioning_stuck_on_loading_screen',
  },
];

const Troubleshooting = ({ roomName, variant = 'text' }: { roomName?: string; variant?: 'text' | 'listItem' }) => {
  const classes = useStyles();
  const scope: any = useScope();
  const hospitalName = scope?.hospital?.name;

  const [openDialog, setOpenDialog] = useState(false);
  const [issue, setIssue] = useState<string>('');
  const [otherIssue, setOtherIssue] = useState('');
  const [sending, setSending] = useState(false);
  const [alert, setAlert] = useState<TroubleshootingAlertProps>({ open: false });
  const [issueList] = useState(issueListOptions); // Initialize issueList as state
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleIssueChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    setIssue(event.target.value as string);
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const clearForm = () => {
    setIssue('');
    setOtherIssue('');
  };

  const handleCloseSnackbar = (event: any) => {
    setAlert({ ...alert, open: false });
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [createSupportTicket] = useMutation(
    gql`
      mutation createSupportTicket($subject: String!, $comment: String!) {
        createSupportTicket(subject: $subject, comment: $comment)
      }
    `,
    {
      variables: {
        subject: '',
        comment: '',
      },
    }
  );

  const handleSubmit = async event => {
    event.preventDefault();
    setSending(true);
    try {
      const result = await createSupportTicket({
        variables: {
          subject: `Support Request • ${hospitalName} • ${roomName || 'Unknown Room'} • ${
            issueList.find(item => item.value === issue)?.label || 'Other'
          } ${otherIssue ? ' •' + otherIssue : ''}`,
          comment: otherIssue,
        },
      });

      if (result.data.createSupportTicket) {
        setAlert({ open: true, severity: 'success', message: 'Request submitted successfully!' });
        handleCloseDialog();
        clearForm();
      } else {
        setAlert({ open: true, severity: 'error', message: 'Unable to submit request at this time' });
      }
    } catch (error: any) {
      if (error?.response) {
        setAlert({ open: true, severity: 'error', message: error?.response.data.description });
        console.error('Error response:', error.response.data);
      } else if (error?.request) {
        setAlert({ open: true, severity: 'error', message: error?.request.message });
        console.error('Error request:', error.request);
      } else {
        setAlert({ open: true, severity: 'error', message: error?.message });
        console.error('Error message:', error.message);
      }
    } finally {
      setSending(false);
    }
  };

  return (
    <>
      {variant === 'text' ? (
        <Box display="flex" justifyContent="center" alignItems="center" className={classes.root}>
          <Typography>Experiencing problems with the app?</Typography>
          <Box>
            <Button variant="text" onClick={refreshScreen} color="secondary">
              Click to refresh
            </Button>
            <Button onClick={handleClickOpenDialog} color="secondary">
              Click here for support
            </Button>
          </Box>
        </Box>
      ) : (
        <>
          <ListItem button onClick={handleClick}>
            <ListItemIcon>
              <HelpOutlined />
            </ListItemIcon>
            <ListItemText primary="Having trouble?" />
          </ListItem>

          <Menu
            id="troubleshooting-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={refreshScreen}>Refresh Screen</MenuItem>
            <MenuItem onClick={handleClickOpenDialog}>Support request</MenuItem>
          </Menu>
        </>
      )}

      <TroubleshootingDialog
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        handleSubmit={handleSubmit}
        handleIssueChange={handleIssueChange}
        issueList={issueList}
        issue={issue}
        setOtherIssue={setOtherIssue}
        sending={sending}
      />

      <TroubleshootingSnackbar alert={alert} handleCloseSnackbar={handleCloseSnackbar} />
    </>
  );
};

export default Troubleshooting;
