import * as XLSX from 'xlsx';

export default (list, columns, data, scope, fileName = 'export-' + ((new Date().getTime() / 1000) | 0) + '.xlsx') => {
  const json = [];
  list.forEach(_ => {
    if (_ && !_.separator) {
      const item = {};
      columns.forEach(column => {
        let value = column.lens(_, { data }, scope);
        if (value && typeof value === 'object') {
          const key = column.key;
          value = key in value ? value[key] : value.id;
        }
        item[column.title] = value ? value : '';
      });
      json.push(item);
    }
  });

  const worksheet = XLSX.utils.json_to_sheet(json);

  XLSX.utils.sheet_add_aoa(worksheet, [columns.map(_ => _.title)], { origin: 'A1' });

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  XLSX.writeFile(workbook, fileName);
};
