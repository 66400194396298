import React, { Fragment, useCallback, useMemo } from 'react';
import get from 'lodash/get';
import defaultTo from 'lodash/defaultTo';
import { withRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import SensorGrid from './SensorGrid';
import SensorList from './SensorList';
import { sensorsDistribution, subscription, subscriptionAllSensors } from '../../../graph/sensors';
import SensorAlerts from './SensorAlerts';
import styled from 'styled-components';
import { useSubscription } from '@apollo/client';
import { compose } from 'recompose';
import withFilterQueryString from '../../../se/hocs/withFilterQueryString';
import ColumnSelect from '../../../se/components/collection/ColumnSelect';
import DateFilter from '../analytics/DateFilter';
import useLocalStorage from 'use-localstorage-hook';
import keyBy from 'lodash/keyBy';
import SensorGlobalConfig from './SensorGlobalConfig';
import responsive from '../../../se/utilities/responsive';
import TabNavigation from './TabNavigation';
import PrintDialog from './PrintDialog';
import { NamedRange } from '../../core/DatePicker';
import TitleAction from '../../../se/components/TitleAction';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withSession } from '../../../state/Session';
import { unpackSessionObject } from '../../pages/unpackSessionObject';
import { withScope } from '../../../contexts/ScopeContext';
export const Header = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  > span {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    max-height: 2.65625rem;
    ${responsive.md.andLarger`
      > * + * {
        margin-left: 0.5rem;
      }
    `}
  }

  ${responsive.md.andSmaller`
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr;
    margin-top: 1.5em;
    > span {
      margin-bottom: 3em;
      > * {
        width: 100%;
      }
      > * + * {
        margin-top: 0.5rem;
      }
    }
  `}
`;

const tabs = match => [
  {
    id: 'chart',
    name: 'Chart View',
    // icon: 'insert_chart_outlined',
    pathname: match.url,
  },
  {
    id: 'list',
    name: 'List View',
    // icon: 'toc',
    pathname: `${match.url}/list`,
  },
  {
    id: 'alert',
    name: 'Alerts',
    // icon: 'notifications_none',
    pathname: `${match.url}/alert`,
  },
];
const fillEmpty = (arr, def) => (arr && arr.length === 0 ? def : arr);

const Title = () => <Typography variant="h2">Temp & Humidity</Typography>;

const SensorPage = ({ filter, setFilter, scope, location, match, isSuperAdmin }) => {
  const sensorSubscription = isSuperAdmin ? subscriptionAllSensors : subscription;
  const { data, loading } = useSubscription(sensorSubscription);
  const alertData = useSubscription(subscription);
  const sensors = isSuperAdmin ? get(data, 'sensorsAll', []) : get(data, 'sensors', []);
  const alertSensors = get(alertData?.data, 'sensors', []);

  const sensorOptions = sensors.map(s => ({ value: s.id, title: `${s.id} (${s.location})` }));
  const sensorOptionsAlert = alertSensors.map(s => ({ value: s.id, title: `${s.id} (${s.location})` }));

  const [selectedSensors, setSelectedSensors] = useLocalStorage('selected-sensors', []);
  const sensorKey = keyBy(sensorOptions, 'value');
  const sensorKeyAlert = keyBy(sensorOptionsAlert, 'value');

  const cleanSelectedSensors = useMemo(
    () =>
      fillEmpty(
        defaultTo(selectedSensors, []).filter(sensor => sensor.value in sensorKey),
        sensorOptions
      ),
    [selectedSensors, sensorKey, sensorOptions]
  );
  const cleanSelectedSensorsAlert = useMemo(
    () =>
      fillEmpty(
        defaultTo(selectedSensors, []).filter(sensor => sensor.value in sensorKeyAlert),
        sensorOptionsAlert
      ),
    [selectedSensors, sensorKeyAlert, sensorOptionsAlert]
  );

  const sensorIds = defaultTo(
    cleanSelectedSensors.map(_ => _.value),
    []
  );

  const sensorIdsAlert = defaultTo(
    cleanSelectedSensorsAlert.map(_ => _.value),
    []
  );

  const filteredSensors = useMemo(() => sensors.filter(s => sensorIds.includes(s.id)), [sensors, sensorIds]);

  const isAlertPage = new RegExp('sensors\\/alert\\/\\d+$', 'gi').test(location.pathname);

  const now = useMemo(() => new Date(), []);

  const onDateRangeFilterChange = useCallback(val => setFilter({ dateRange: val }), [setFilter]);

  const onSelectedSensorsChange = useCallback(c => setSelectedSensors(c), [setSelectedSensors]);

  return (
    <Fragment>
      <TitleAction Title={Title}>
        {!isAlertPage && (
          <Grid container spacing={1}>
            <Grid item>
              <ColumnSelect
                options={sensorOptions}
                values={cleanSelectedSensors}
                onChange={onSelectedSensorsChange}
                labelField={'title'}
                valueField={'value'}
                label={'Choose Sensors'}
              />
            </Grid>
            <Grid item>
              <DateFilter value={get(filter, 'dateRange')} onChange={onDateRangeFilterChange} now={now} />
            </Grid>
          </Grid>
        )}
      </TitleAction>
      <Box display="flex" justifyContent="space-between" flexWrap="wrap" flexShrink={0}>
        <TabNavigation tabs={tabs(match)} location={location} showName />
        {!isAlertPage && (
          <Box display="flex" alignItems="center" mt={2}>
            <PrintDialog scope={scope} sensorIds={sensorIds} filter={filter} />
            <SensorGlobalConfig
              refetch={{
                query: sensorsDistribution,
                variables: { dateRange: filter.dateRange.toJSON(), sensors: sensorIds },
              }}
            />
          </Box>
        )}
      </Box>
      <Switch>
        <Route
          path={`${match.path}/alert`}
          render={() => (
            <SensorAlerts sensorIds={sensorIdsAlert} parentFilter={{ dateRange: filter.dateRange.toJSON() }} />
          )}
        />
        <Route path={`${match.path}/list`} render={() => <SensorList sensors={filteredSensors} />} />
        <Route
          exact
          path={`${match.path}/`}
          render={() => (
            <SensorGrid
              sensors={defaultTo(get(data, 'sensors'), [])}
              loading={loading}
              filteredSensors={filteredSensors}
              sensorIds={sensorIds}
              filter={filter}
            />
          )}
        />
      </Switch>
    </Fragment>
  );
};

export default compose(
  withFilterQueryString({
    dateRange: NamedRange.lastSevenDays(),
  }),
  withRouter,
  withScope,
  withSession(unpackSessionObject)
)(SensorPage);
