import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import React, { ReactNode, Ref, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withSession, isGroupAdmin } from '@/state/Session';
import { ClickAwayListener, Grow, MenuList, Paper, Popper } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MenuItem from '@material-ui/core/MenuItem';

interface HospitalNameProps {
  compressed: boolean;
  children: ReactNode;
  memberships: any;
  selectMembership: any;
}

const HospitalName = ({ compressed, children, groupAdmin, memberships, selectMembership }: HospitalNameProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>();

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      {!groupAdmin && Array.isArray(memberships) && memberships.length > 1 ? (
        <div
          ref={anchorRef as Ref<HTMLDivElement>}
          onClick={handleToggle}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer' }}
        >
          <Typography
            color="textSecondary"
            className={clsx(classes.hospitalName, {
              [classes.hideName]: compressed,
            })}
          >
            {children}
          </Typography>
          <ArrowDropDownIcon />
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            style={{
              zIndex: 2000,
            }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu">
                      {memberships.map(membership => (
                        <MenuItem key={membership.id} onClick={() => selectMembership(membership.id)}>
                          {membership.organization.name}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      ) : (
        <Typography
          color="textSecondary"
          className={clsx(classes.hospitalName, {
            [classes.hideName]: compressed,
          })}
        >
          {children}
        </Typography>
      )}
    </>
  );
};

export default withSession(context => ({
  groupAdmin: isGroupAdmin(context.session?.user),
  memberships: context.session?.user?.memberships,
  selectMembership: context.selectMembership,
}))(HospitalName);

const useStyles = makeStyles(theme => ({
  hospitalName: {
    whiteSpace: 'normal',
  } as any,
  hideName: {
    opacity: 0,
    transition: theme.transitions.create('none', {
      delay: 0,
    }),
  } as any,
}));
