import React, { ReactNode, PureComponent, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from 'recharts';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';

interface Mark {
  label?: string;
  isHighlighted?: boolean;
}

interface TimelineColumnLegendProps {
  title?: string;
  description?: string;
  children?: ReactNode;
  graphic?: string;
}

interface ColumnLegendProps {
  title?: string;
  description?: string;
  children?: ReactNode;
  marks: Mark[] | number[];
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
  },
  timelineItem: {
    '&:before': {
      display: 'none',
    },
  },
}));

const data = [
  { name: 'In OR', value: 10 },
  { name: 'In surgery', value: 10 },
  { name: 'Closing', value: 30 },
  { name: 'Procedure complete', value: 20 },
  { name: 'Cleaning', value: 20 },
  { name: 'OR ready', value: 30 },
];

const renderActiveShape = props => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value, name } =
    props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      {/*<text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#fff">*/}
      {/*  {name}*/}
      {/*</text>*/}
    </g>
  );
};

function TimelineCustom({ marks }: { marks: Mark[] }) {
  const classes = useStyles();
  const numberOfItems = marks.length;

  return (
    <>
      {marks.map((item, index) => (
        <TimelineItem
          classes={{
            root: classes.timelineItem,
          }}
          key={index}
        >
          <TimelineSeparator>
            <TimelineDot
              variant={item.isHighlighted ? 'default' : 'outlined'}
              color={item.isHighlighted ? 'primary' : 'grey'}
            />
            {++index !== numberOfItems && (
              <TimelineConnector
                classes={{ root: item.isHighlighted && marks[index].isHighlighted ? classes.root : undefined }}
              />
            )}
          </TimelineSeparator>
          <TimelineContent>
            {item.label || (
              <Box mt={1}>
                <Divider />
              </Box>
            )}
          </TimelineContent>
        </TimelineItem>
      ))}
    </>
  );
}

export const TimelineColumnLegend = ({ title, description, children, graphic }: TimelineColumnLegendProps) => (
  <Box width="45ch" px={4} py={2}>
    <Typography variant="h5" gutterBottom>
      {title}
    </Typography>
    <Typography variant="body2" color="textSecondary" gutterBottom>
      {description}
    </Typography>

    {graphic && (
      <Box mt={4} mb={2}>
        <img src={graphic} width="100%" />
      </Box>
    )}
    {children}
  </Box>
);

const ColumnLegend = ({ title, description, children, marks }: ColumnLegendProps) => {
  const [activeIndex, setActiveIndex] = useState(marks);

  const customLabel = entry => entry.name;

  return (
    <Box width="40ch" pl={4} pr={4} pt={2} pb={2}>
      <Typography variant="h5" gutterBottom>
        {title}
      </Typography>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        {description}
      </Typography>

      <ResponsiveContainer width="100%" height={300}>
        <PieChart width={400} height={300}>
          <Pie
            startAngle={90}
            endAngle={-270}
            data={data}
            cx="50%"
            cy="50%"
            innerRadius={65}
            outerRadius={80}
            paddingAngle={5}
            fill="#8884d8"
            dataKey="value"
            stroke="none"
            label={customLabel}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={activeIndex.includes(index) ? '#00A7F7' : 'rgb(51,59,133)'} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>

      {children}
    </Box>
  );
};

export default ColumnLegend;
