import React, { useEffect, useState } from 'react';
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel } from '@material-ui/core';
import { ChecklistAnswer, YesCheckboxAnswerWithChildren } from '../../types/Answer';
import { ChecklistQuestion } from '../../types/Question';
import { isBoolean } from '../../se/utilities/check';
import { useSave } from '../../components/pages/kiosk/charting/SaveProvider';
import isArray from 'lodash/isArray';
import Chip from '@material-ui/core/Chip';
import { useSlideIn } from '../Form';
import ReactHtmlParser from 'react-html-parser';
import Button from '@material-ui/core/Button';

type ChecklistInputProps = ChecklistQuestion['props'] & {
  name?: string;
  answer?: ChecklistAnswer;
  onChange?: (newAnswer: ChecklistAnswer) => void;
};

const ChecklistInput = ({ optional, label, checkboxes, horizontal, answer, onChange }: ChecklistInputProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const changed = { [event.target.name]: event.target.checked };
    const newAnswer = answer ? { ...answer, ...changed } : changed;
    const completed = count(Object.entries(newAnswer ?? {}), ([, value]) => (isArray(value) ? value[0] : value));
    onChange?.(newAnswer);

    if (completed === checkboxes.length) {
      setInvalid(false);
    }
  };

  const [invalid, setInvalid] = useState(false);

  const { validationRequest } = useSave();
  const slideIn = useSlideIn();

  const completed = count(Object.entries(answer ?? {}), ([, value]) => (isArray(value) ? value[0] : value));

  useEffect(() => {
    if (optional) {
      return;
    }

    if (validationRequest) {
      if (!answer || completed < checkboxes.length) {
        setInvalid(true);
        slideIn();
      }
    }
  }, [validationRequest, answer, optional, completed, checkboxes.length, slideIn]);

  const selectAll = () => {
    onChange?.(checkboxes.map(e => e.name).reduce((res, e) => ({ ...res, [e]: true }), {}));
  };

  const deselectAll = () => {
    onChange?.({});
  };

  return (
    <Box my={3} display="flex" textAlign="start">
      <FormControl component="fieldset" error={invalid}>
        {label && <FormLabel component="legend">{ReactHtmlParser(label)}</FormLabel>}
        <Box display="flex" style={{ gap: '1em' }}>
          <FormHelperText>
            <Chip
              size="small"
              variant="default"
              color={completed === checkboxes.length ? 'primary' : invalid ? 'secondary' : 'default'}
              label={`${completed} / ${checkboxes.length}`}
            />
          </FormHelperText>
          <Button onClick={selectAll}>Select All</Button>
          <Button onClick={deselectAll}>Deselect All</Button>
        </Box>
        <FormGroup>
          <Box display="flex" flexDirection={horizontal ? 'row' : 'column'}>
            {checkboxes.map(checkbox => {
              const { name, label } = checkbox;
              const checkboxAnswer: boolean | YesCheckboxAnswerWithChildren | undefined = answer?.[name];
              const checkboxValue = isBoolean(checkboxAnswer)
                ? !!checkboxAnswer
                : !!(checkboxAnswer as YesCheckboxAnswerWithChildren)?.value;
              return checkbox?.child ? (
                <Box key={name}>
                  <FormControlLabel
                    control={<Checkbox color="primary" checked={checkboxValue} onChange={handleChange} name={name} />}
                    label={ReactHtmlParser(label)}
                  />
                  {checkboxValue && (
                    <Box mx={2} mt={-2}>
                      {checkbox?.child}
                    </Box>
                  )}
                </Box>
              ) : (
                <FormControlLabel
                  key={name}
                  control={<Checkbox color="primary" checked={checkboxValue} onChange={handleChange} name={name} />}
                  label={ReactHtmlParser(label)}
                />
              );
            })}
          </Box>
        </FormGroup>
      </FormControl>
    </Box>
  );
};

export default ChecklistInput;

const count = <T,>(arr: T[], predicate: (item: T) => boolean) =>
  arr.reduce((acc, item) => (predicate(item) ? acc + 1 : acc), 0);
