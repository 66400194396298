import React, { useMemo, useRef } from 'react';
import Select from 'react-select';
import { withTheme } from 'styled-components';

import { withTheme as withSpicyTheme } from '../../theme';
import InputError from '../errors/InputError';
import selectStyles from './selectStyles';
import { sortString } from '../../../util/sort';
import TextInput from './TextInput';
import get from 'lodash/get';
import find from 'lodash/find';
import { useClickawayPresence } from '../../../contexts/ClickawayPresence';

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data.label}</span>
  </div>
);

const createOptionObject = object =>
  Object.entries(object).reduce((acc, [value, label]) => [...acc, { value, label }], []);

const deepSelectOptions = object => {
  if (typeof object !== 'object') {
    return undefined;
  } else {
    if (typeof Object.values(object).pop() === 'object') {
      return Object.entries(object).reduce(
        (acc, [label, valueObj]) => [...acc, { options: createOptionObject(valueObj), label }],
        []
      );
    } else {
      return createOptionObject(object);
    }
  }
};

const SelectInput = ({
  Error = InputError,
  options = {},
  autoFocus,
  value,
  defaultValue = '',
  onChange,
  name = 'select',
  placeholder = '',
  error,
  hasError,
  getOptionValue,
  menuWidthFollowContent,
  required,
  isClearable,
  isSearchable,
  style,
  sortOptions,
  disabled,
  maxMenuHeight,
  ...rest
}) => {
  const theme = withSpicyTheme(theme => theme)({ ...rest });
  const customStyles = selectStyles({ theme, menuWidthFollowContent, hasError });
  const selectOptions = useMemo(() => deepSelectOptions(options), [options]);

  const clickawayPresenceDeregisterRef = useRef(() => {});
  const clickawayPresence = useClickawayPresence();

  const sortedOptions = sortOptions ? selectOptions.sort((l, r) => sortString(l.label, r.label)) : selectOptions;

  const valueField = useMemo(() => {
    if (Array.isArray(value)) {
      return value.flatMap(v => {
        const option = selectOptions.find(_ => _.value === `${v === null || v === undefined ? defaultValue : v}`);
        return option ? [option] : [];
      });
    }
    if (!selectOptions) {
      return ``;
    }
    if (selectOptions[0] && selectOptions[0].value) {
      return selectOptions.find(_ => _.value === `${value === null || value === undefined ? defaultValue : value}`);
    } else if (selectOptions[0] && selectOptions[0].options) {
      for (const i of selectOptions) {
        const opt = i.options.find(_ => _.value === `${value === null || value === undefined ? defaultValue : value}`);
        if (opt) return opt;
      }
    } else {
      return ``;
    }
  }, [selectOptions, value, defaultValue]);

  if (disabled) {
    return (
      <TextInput
        disabled={true}
        value={get(
          find(sortedOptions, _ => _.value === String(value)),
          'label'
        )}
      />
    );
  }

  return (
    <div style={style}>
      <Select
        id={name}
        inputId={name}
        name={name}
        autoComplete="on"
        autoCapitalize="off"
        autoCorrect="off"
        isClearable={isClearable !== false}
        isSearchable={isSearchable !== false}
        backspaceRemovesValue={false}
        autoFocus={autoFocus || false}
        options={sortedOptions}
        getOptionLabel={option => option.label}
        value={valueField || ``}
        onChange={option => onChange(getOptionValue(option))}
        styles={customStyles}
        placeholder={placeholder}
        maxMenuHeight={maxMenuHeight || 250}
        menuPlacement={'auto'}
        menuPortalTarget={document.getElementById('select-menu')}
        disabled={disabled}
        formatGroupLabel={formatGroupLabel}
        onMenuOpen={() => {
          clickawayPresenceDeregisterRef.current = clickawayPresence.register();
        }}
        onMenuClose={() => {
          clickawayPresenceDeregisterRef.current();
          clickawayPresenceDeregisterRef.current = () => {};
        }}
        {...rest}
      />
      <input
        tabIndex={-1}
        value={value || defaultValue}
        onChange={() => {}}
        required={required || (value || defaultValue) === null || (value || defaultValue) === undefined}
        style={{
          opacity: 0,
          width: 0,
          height: 0,
          position: 'absolute',
        }}
      />
      <Error isVisible={hasError} id={name}>
        {hasError ? error : ''}
      </Error>
    </div>
  );
};

SelectInput.defaultProps = {
  getOptionValue: option =>
    Array.isArray(option) ? option.map(o => (o ? o.value : undefined)) : option ? option.value : undefined,
  menuWidthFollowContent: false,
};

export default withTheme(SelectInput);
