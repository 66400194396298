import { getMinutes, parse } from 'date-fns';
import { getHours } from 'date-fns';
import addMinutes from 'date-fns/add_minutes';
import { translateToTz } from './dateTime';
import { maxBy, minBy } from 'lodash';

export const getFirstRoomEntry = orRoomTimes => minBy(orRoomTimes, ({ enteredAt }) => enteredAt).enteredAt;

export const getLastRoomExit = orRoomTimes => maxBy(orRoomTimes, ({ exitedAt }) => exitedAt).exitedAt;

export const getRoomEntry = orRoomTimes => [getFirstRoomEntry(orRoomTimes), getLastRoomExit(orRoomTimes)];

export const procedureAccuracy = (startTime, duration, enteredAt, exitedAt, timeZone) => {
  if (!startTime || !duration || !enteredAt || !exitedAt) return '0%';

  const timeScheduledStart = translateToTz(timeZone)(parse(startTime)).getTime();
  const timeScheduledEnd = translateToTz(timeZone)(addMinutes(parse(startTime), duration)).getTime();
  const timeORStart = translateToTz(timeZone)(parse(enteredAt)).getTime();
  const timeOREnd = translateToTz(timeZone)(parse(exitedAt)).getTime();

  const scheduledDuration = timeScheduledEnd - timeScheduledStart;
  const actualDuration = timeOREnd - timeORStart;

  if (scheduledDuration <= 0 || actualDuration <= 0) return '0%';

  const accuracy = (1 - Math.abs(scheduledDuration - actualDuration) / scheduledDuration) * 100;

  return `${accuracy.toFixed(2)}%`;
};

export const convertHoursAndMinutesForSlider = (startTime, duration, enteredAt, exitedAt, timeZone) => {
  const [timeScheduledStart, timeScheduledEnd, timeORStart, timeOREnd] = [
    translateToTz(timeZone)(parse(startTime)).getTime(),
    translateToTz(timeZone)(addMinutes(parse(startTime), duration)).getTime(),
    translateToTz(timeZone)(parse(enteredAt)).getTime(),
    translateToTz(timeZone)(parse(exitedAt)).getTime(),
  ];

  const tss = getHours(timeScheduledStart) + getMinutes(timeScheduledStart) / 60;
  const tse = getHours(timeScheduledEnd) + getMinutes(timeScheduledEnd) / 60;
  const tos = getHours(timeORStart) + getMinutes(timeORStart) / 60;
  const toe = getHours(timeOREnd) + getMinutes(timeOREnd) / 60;
  return [
    [tss, tse],
    [tos, toe],
    [Math.min(tss, tse, tos, toe) - 1, Math.max(tss, tse, tos, toe) + 1],
  ];
};

export const getHoursAndMinutesTextForSlider = decimalValue => {
  const hours = Math.trunc(decimalValue);
  const minutes = Math.round((decimalValue - Math.trunc(decimalValue)) * 60);
  return `${hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
};
