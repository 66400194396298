import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Box, CssBaseline, Divider } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useQuery, useSubscription } from '@apollo/client';
import { Patient } from '../../../../../types/Patient';
import { QuestionnaireAndAnswers, QuestionnaireType } from '../../../../../types/Questionnaire';
import { sortBy } from 'lodash';
import MyPatientInfo from '../PatientInfo';
import { optionalFormat } from '../../tablet/utils';
import { patientEnteredRoomTypeTime } from '../../../../entities/patient/util/patientEvents';
import { item as patientWithCharts } from '../../../../../graph/patientChart';
import { calculateBMIStr } from '../../../../../questionnaire/toBMI';
import { parseHeight } from '../../../../../form/questions/TextInput';
import { ThemeProvider } from '@material-ui/core/styles';
import { tabletThemeLight } from '../../../../../themes/tabletTheme';
import { matchPath } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import { RoomType } from '../../../../../types/Room';
import FooterSession from '../../tablet/components/FooterSession';
import ChartInfo from '../ChartInfo';
import Fab from '@material-ui/core/Fab';
import { History } from 'history';
import { LastLocationType } from 'react-router-last-location/src/LastLocationContext';
import CloseIcon from '@material-ui/icons/Close';
import { getAllChartingData, getChartingData } from '../utils';
import ChartingButtons from '../ChartingButtons';
import { list } from '../../../../../graph/config';
import { ConfigType, PatientChartButtonsConfig } from '../../../../../types/HospitalConfig';
import { tryParseJson } from '../../../../../util/parseJson';
import DictationPanel from '../DictationPanel';
import { ChartsContext } from '../../../../../form/Form';
import { DictationsContextProvider } from '../../../../../contexts/DictationsContext';
import { useChartingSession } from '../modules/hooks';
import { withPin } from '../modules/withPin';

export const getEnteredAt = (questionnaireType: QuestionnaireType, patient?: Patient | null) => {
  switch (questionnaireType) {
    case QuestionnaireType.PreOpChart:
    case QuestionnaireType.AnesthesiologistPreOpChart:
      return optionalFormat(patientEnteredRoomTypeTime(patient, RoomType.PreOp), 'HH:mm');
    case QuestionnaireType.OrChart:
    case QuestionnaireType.AnesthesiologistOrChart:
    case QuestionnaireType.PhysicianChart:
      return optionalFormat(patientEnteredRoomTypeTime(patient, RoomType.OR), 'HH:mm');
    case QuestionnaireType.PacuChart:
    case QuestionnaireType.AnesthesiologistPacuChart:
      return optionalFormat(patientEnteredRoomTypeTime(patient, RoomType.PACU), 'HH:mm');
    default:
      return null;
  }
};

const ChartingProfile = withPin({
  CloseButton: withRouter(({ history }) => {
    const lastLocation = useLastLocation();
    const handleGoBack = () => goBack(history, lastLocation);
    return (
      <Fab onClick={handleGoBack}>
        <CloseIcon />
      </Fab>
    );
  }),
})(
  withRouter(
    ({
      hospitalId,
      patientId,
      questionnaireType,
      history,
    }: {
      hospitalId: number;
      patientId: number;
      questionnaireType: QuestionnaireType;
      room: string;
    } & RouteComponentProps) => {
      const classes = useStyles();

      const { data: patientData } = useSubscription(patientWithCharts, {
        variables: { id: patientId },
        skip: !patientId,
      });
      const patient: Patient | undefined = patientData?.patient;

      const charts: QuestionnaireAndAnswers[] | undefined = sortBy(
        getChartingData(questionnaireType, patient),
        'questionnaire.order'
      );

      const preOpNurseCharts: any = (getChartingData(QuestionnaireType.PreOpChart, patient) || []).reduce(
        (acc, e) => ({
          ...acc,
          ...JSON.parse(e?.questionnaireExchange?.answers || '{}'),
        }),
        {}
      );

      const allCharts = getAllChartingData(patient);

      const mergedAnswers = charts?.reduce(
        (acc, e) => ({
          ...acc,
          ...JSON.parse(e?.questionnaireExchange?.answers || '{}'),
        }),
        {}
      );
      const calculatedValues = {
        bmi: calculateBMIStr((mergedAnswers as any)?.wt, ...parseHeight((mergedAnswers as any)?.ht)),
      };
      const combined = { ...mergedAnswers, ...calculatedValues };
      const preOpNurseChartsCombined = {
        ...preOpNurseCharts,
        bmi: calculateBMIStr((preOpNurseCharts as any)?.wt, ...parseHeight((preOpNurseCharts as any)?.ht)),
      };

      const enteredAt = getEnteredAt(questionnaireType, patient);

      const lastLocation = useLastLocation();

      const handleGoBack = () => goBack(history, lastLocation);

      const fallRiskAssessment = patient?.procedure?.fallRiskAssessment;

      const chartingSession = useChartingSession();
      const { data: configData } = useQuery(list, {
        variables: {
          type: ConfigType.PatientChartButtons,
        },
      });
      const tabsConfig: PatientChartButtonsConfig | null = tryParseJson(configData?.configs?.[0]?.values);
      const ChartingButtonsComponent = ChartingButtons(tabsConfig, chartingSession?.role || 'staff');

      const [showDictation, setShowDictation] = useState<boolean>(false);

      return (
        <ThemeProvider theme={tabletThemeLight}>
          <CssBaseline />
          <ChartsContext.Provider value={allCharts}>
            <Box className={classes.viewport}>
              <Box className={classes.container}>
                <Box display="flex" justifyContent="flex-end">
                  <ChartingButtonsComponent data={patient} onDictationButtonClick={() => setShowDictation(v => !v)} />
                </Box>
                <Divider />
                <MyPatientInfo
                  onBack={handleGoBack}
                  patientName={patient?.name}
                  physicianName={patient?.physician?.name}
                  anesthesiologistName={patient?.procedure?.anesthesiologist?.name}
                  anesthesiaType={patient?.procedure?.anesthesiaType}
                  anesthesiaReviewed={patient?.procedure?.anesthesiaReviewed}
                  procedureTypeName={patient?.procedureType?.name}
                  enteredAt={enteredAt}
                  dateOfBirth={patient?.procedure?.patientDateOfBirth}
                  age={patient?.procedure?.patientAge}
                  sex={patient?.procedure?.patientSex}
                  hstId={patient?.procedure?.patientHstId}
                  fallRiskAssessment={
                    !fallRiskAssessment
                      ? undefined
                      : fallRiskAssessment!.toLowerCase().indexOf('high') > -1
                        ? 'high'
                        : fallRiskAssessment!.toLowerCase().indexOf('low') > -1
                          ? 'low'
                          : undefined
                  }
                  allergies={patient?.procedure?.allergies}
                />
                <DictationsContextProvider>
                  <Box flex={1} display="flex" flexDirection="column">
                    {showDictation ? (
                      <DictationPanel patientId={patientId} procedureId={patient?.procedure?.id} />
                    ) : (
                      <ChartInfo
                        hospitalId={hospitalId}
                        patientId={patientId}
                        patient={patient}
                        combined={preOpNurseChartsCombined}
                        questionnaireType={questionnaireType}
                      />
                    )}
                  </Box>
                </DictationsContextProvider>
                <FooterSession />
              </Box>
            </Box>
          </ChartsContext.Provider>
        </ThemeProvider>
      );
    }
  )
);

export const useStyles = makeStyles(theme => ({
  viewport: {
    overflow: 'hidden',
    display: 'flex',
    flex: 1,
    gap: 0,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(5),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(3, 4),
    },
    gap: theme.spacing(4),
    overflow: 'auto',
  },
}));

export default ChartingProfile;

const goBack = (history: History<unknown>, lastLocation: LastLocationType) => {
  const isChartingPage = history.location.pathname.includes('/charting');
  const isChartDetailPage = history.location.pathname.includes('-chart');

  if (!isChartDetailPage) {
    if (lastLocation) {
      history.replace(lastLocation);
      return;
    }
  }

  if (isChartingPage) {
    const match = matchPath(history.location.pathname, '/kiosk/:id/charting');

    if (match) {
      const rest = history.location.pathname.substring(match.url.length);
      const chart = rest.match(/\/([^\/]+)-chart/);

      if (chart) {
        // Extract the number from lastLocation’s pathname
        const lastLocationNumber = lastLocation?.pathname.match(/\/(\d+)$/);

        if (lastLocationNumber) {
          history.replace(`${match.url}/${chart[1]}/${lastLocationNumber[1]}` + window.location.search);
        } else {
          history.replace(match.url + '/' + chart[1] + window.location.search);
        }
      } else {
        history.replace(match.url + window.location.search);
      }

      return;
    }
  }

  history.goBack();
};
