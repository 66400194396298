import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import { getNestedValue } from '../../../../se/utilities/data/object';
import { ShiftState } from '../../../../types/Procedure';
import { PROCEDURE_STATUSES } from '../../../entities/patient/enums';
import { HospitalInfo } from '../../../HospitalInfo';
import PanelStatus from '../../../Panel/PanelStatus';
import { eventTime, getLogEntries, isHelpFn, patientEnteredTime } from '../tablet/utils';
import BottomPanelStatus from './BottomPanelStatus';
import { PanelsStatus } from './Monitor';

// TODO @zhaotjack please remove this when it is no longer needed
export const mockStaff = [
  {
    id: 1,
    shiftState: ShiftState.AVAILABLE,
    staff: {
      id: 1,
      name: 'John Doe',
      role: 'Nurse',
    },
    date: '2021-08-01',
    from: '08:00',
    to: '16:00',
    note: 'Note',
    openPosition: {
      id: 1,
      name: 'Nurse',
      description: 'Nurse description',
    },
    hourlyRate: 100,
  },
  {
    id: 2,
    shiftState: ShiftState.AVAILABLE,
    staff: {
      id: 2,
      name: 'Jane Doe',
      role: 'Nurse',
    },
    date: '2021-08-02',
    from: '08:00',
    to: '16:00',
    note: 'Note',
    openPosition: {
      id: 2,
      name: 'Nurse',
      description: 'Nurse description',
    },
    hourlyRate: 100,
  },
  {
    id: 3,
    shiftState: ShiftState.AVAILABLE,
    staff: {
      id: 3,
      name: 'John Smith',
      role: 'Nurse',
    },
    date: '2021-08-03',
    from: '08:00',
    to: '16:00',
    note: 'Note',
    openPosition: {
      id: 3,
      name: 'Nurse',
      description: 'Nurse description',
    },
    hourlyRate: 100,
  },
  {
    id: 3,
    shiftState: ShiftState.AVAILABLE,
    staff: {
      id: 3,
      name: 'John Smith',
      role: 'Nurse',
    },
    date: '2021-08-03',
    from: '08:00',
    to: '16:00',
    note: 'Note',
    openPosition: {
      id: 3,
      name: 'Nurse',
      description: 'Nurse description',
    },
    hourlyRate: 100,
  },
  {
    id: 3,
    shiftState: ShiftState.AVAILABLE,
    staff: {
      id: 3,
      name: 'John Smith',
      role: 'Nurse',
    },
    date: '2021-08-03',
    from: '08:00',
    to: '16:00',
    note: 'Note',
    openPosition: {
      id: 3,
      name: 'Nurse',
      description: 'Nurse description',
    },
    hourlyRate: 100,
  },
  {
    id: 3,
    shiftState: ShiftState.AVAILABLE,
    staff: {
      id: 3,
      name: 'John Smith',
      role: 'Nurse',
    },
    date: '2021-08-03',
    from: '08:00',
    to: '16:00',
    note: 'Note',
    openPosition: {
      id: 3,
      name: 'Nurse',
      description: 'Nurse description',
    },
    hourlyRate: 100,
  },
];

export const MonitorContent = ({
  operationRooms,
  waitingRoom,
  preOp,
  pacu,
  postOps,
  hasPostop,
  hospitalId,
  preOpFocused,
  pacuFocused,
  hasPreOpPriorityModule,
  hasNoteModule,
  isMini,
  isSuperAdmin,
  isLiaison,
  messagingView,
  scope,
  staffMode,
}) => {
  const hasOtherStatuses = waitingRoom || preOp || pacu || (postOps && Array.isArray(postOps) && postOps.length > 0);
  const classes = useStyles();

  return (
    <Box className={clsx(classes.root, { [classes.focused]: preOpFocused || pacuFocused })}>
      <Box display="flex" alignItems="center">
        <Box flex={1}>
          <HospitalInfo scope={scope} />
        </Box>
      </Box>
      {operationRooms.length > 0 && !hasOtherStatuses ? (
        <PanelsStatus miniView={isMini} wrap="true">
          {operationRooms.map(operationRoom => (
            <PanelStatus
              key={operationRoom.id}
              roomId={operationRoom.id}
              title={operationRoom.name}
              monitorStatus={operationRoom.monitorStatus}
              status={operationRoom.status}
              statusLabel={operationRoom.statusLabel}
              scheduleProcedure={operationRoom.procedure}
              procedure={getNestedValue('patient.procedureType.name', operationRoom)}
              physician={getNestedValue('patient.physician.name', operationRoom)}
              patient={getNestedValue('patient.name', operationRoom)}
              patientId={getNestedValue('patient.id', operationRoom)}
              patientType={operationRoom?.patient?.type}
              enteredAtTime={patientEnteredTime(getNestedValue('patient', operationRoom), operationRoom)}
              timeoutTime={eventTime(PROCEDURE_STATUSES.TIME_OUT, getNestedValue('patient', operationRoom))}
              procedureStartTime={eventTime(PROCEDURE_STATUSES.ONGOING, getNestedValue('patient', operationRoom))}
              closingTime={eventTime(PROCEDURE_STATUSES.CLOSING, getNestedValue('patient', operationRoom))}
              compactView={operationRooms.length > 3}
              allowWrap={true}
              miniView={operationRooms.length > 8}
              numberOfRooms={operationRooms.length}
              preOpFocused={preOpFocused}
              pacuFocused={pacuFocused}
              isHelp={isHelpFn(getLogEntries(getNestedValue('patient', operationRoom)))}
              called={getNestedValue('patient.called') && hasOtherStatuses}
              isPreOp={preOp}
              room={operationRoom}
              isSuperAdmin={isSuperAdmin}
              messagingView={messagingView || isLiaison}
              hideTurnoverClock={operationRoom?.hideTurnoverClock}
              warningThreshold={operationRoom?.warningThreshold}
              alertThreshold={operationRoom?.alertThreshold}
            />
          ))}
        </PanelsStatus>
      ) : operationRooms.length === 0 && hasOtherStatuses ? (
        <BottomPanelStatus
          waitingRoom={waitingRoom}
          preOp={preOp}
          pacu={pacu}
          postOps={postOps}
          hasPostop={hasPostop}
          hospitalId={hospitalId}
          preOpFocused={preOpFocused}
          pacuFocused={pacuFocused}
          hasPreOpPriorityModule={hasPreOpPriorityModule}
          hasNoteModule={hasNoteModule}
          isMini={isMini}
          messagingView={messagingView || isLiaison}
          carouselSize={operationRooms.length === 0 ? 9 : 5}
          staffMode={staffMode}
        />
      ) : (
        <>
          <PanelsStatus miniView={isMini} wrap="true">
            {operationRooms.map(operationRoom => (
              <PanelStatus
                key={operationRoom.name}
                roomId={operationRoom.id}
                title={operationRoom.name}
                monitorStatus={operationRoom.monitorStatus}
                status={operationRoom.status}
                statusLabel={operationRoom.statusLabel}
                scheduleProcedure={operationRoom.procedure}
                procedure={getNestedValue('patient.procedureType.name', operationRoom)}
                physician={getNestedValue('patient.physician.name', operationRoom)}
                patient={getNestedValue('patient.name', operationRoom)}
                patientId={getNestedValue('patient.id', operationRoom)}
                patientData={getNestedValue('patient', operationRoom)}
                patientType={operationRoom?.patient?.type}
                enteredAtTime={patientEnteredTime(getNestedValue('patient', operationRoom), operationRoom)}
                timeoutTime={eventTime(PROCEDURE_STATUSES.TIME_OUT, getNestedValue('patient', operationRoom))}
                procedureStartTime={eventTime(PROCEDURE_STATUSES.ONGOING, getNestedValue('patient', operationRoom))}
                closingTime={eventTime(PROCEDURE_STATUSES.CLOSING, getNestedValue('patient', operationRoom))}
                compactView={operationRooms.length > 3}
                allowWrap={true}
                miniView={operationRooms.length > 8}
                numberOfRooms={operationRooms.length}
                preOpFocused={preOpFocused}
                pacuFocused={pacuFocused}
                isHelp={isHelpFn(getLogEntries(getNestedValue('patient', operationRoom)))}
                called={getNestedValue('patient.name.called')}
                isPreOp={preOp}
                room={operationRoom}
                isSuperAdmin={isSuperAdmin}
                hospitalId={hospitalId}
                messagingView={messagingView || isLiaison}
                hideTurnoverClock={operationRoom?.hideTurnoverClock}
                warningThreshold={operationRoom?.warningThreshold}
                alertThreshold={operationRoom?.alertThreshold}
                staffMode={staffMode}
              />
            ))}
          </PanelsStatus>
          <BottomPanelStatus
            waitingRoom={waitingRoom}
            preOp={preOp}
            pacu={pacu}
            postOps={postOps}
            hasPostop={hasPostop}
            hospitalId={hospitalId}
            preOpFocused={preOpFocused}
            pacuFocused={pacuFocused}
            hasPreOpPriorityModule={hasPreOpPriorityModule}
            hasNoteModule={hasNoteModule}
            isMini={isMini}
            messagingView={messagingView || isLiaison}
            staffMode={staffMode}
          />
        </>
      )}
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    width: '100vw',
    minHeight: '100vh',
    height: 'fit-content',
    padding: '0.5rem 1rem 1rem',
  },
  focused: {
    display: 'grid',
    gridTemplateRows: '1fr 5fr 12fr',
  },
}));

export default MonitorContent;
