import React from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import { mockStaff } from '../pages/kiosk/monitor/MonitorContent';
import StaffShifts from '../pages/kiosk/schedule/staff/StaffShifts';
import PanelContainer from './PanelContainer';
import PatientSlot from '../patient/PatientSlot/PatientSlot';
import posed, { PoseGroup } from 'react-pose';
import PanelEmpty from './PanelEmpty';
import Carousel from '../carousel/Carousel';
import { PACU, POST_OP, PRE_OP } from '../entities/room/enums';
import { getFrameColor, getLogEntries, isPreOpAsDPU } from '../pages/kiosk/tablet/utils';

const Slots = styled.span`
  display: flex;
  flex-flow: column;
  grid-row-gap: 0.25rem;
  padding: 0 1rem;
  margin: ${props => (props.patients > 0 ? 'initial' : 'auto')};
  margin-left: -1rem;
  margin-right: -1rem;
`;

const AnimatedPatientSlot = posed(PatientSlot)({
  preEnter: {
    opacity: 0,
    transition: { type: 'spring' },
  },
  enter: {
    opacity: 1,
    marginBottom: '0.25rem',
    transition: {
      duration: 300,
      delay: 500,
      ease: [0.0, 0.01, 0.01, 0.99],
    },
  },
  exit: {
    opacity: 0,
    marginBottom: '-4.875rem',
    transition: {
      duration: 300,
      ease: [0.0, 0.01, 0.01, 0.99],
    },
  },
});

const AnimatedPanelEmpty = posed(PanelEmpty)({
  preEnter: {
    opacity: 0,
    transition: { type: 'spring' },
  },
  enter: {
    scale: 1,
    opacity: 1,
    y: 0,
    transition: {
      duration: 500,
      ease: [0.0, 0.01, 0.01, 0.99],
    },
  },
  exit: {
    scale: 0.8,
    y: -20,
    opacity: 0,
    transition: {
      duration: 500,
      ease: [0.0, 0.01, 0.01, 0.99],
    },
  },
});

const Panel = ({
  roomType,
  name,
  capacity,
  patients,
  patientsCount,
  image,
  highlightReady,
  showPriority,
  showPreOpNote,
  showPacuNote,
  showBed,
  screenType,
  carouselSize,
  fusePanels,
  lastInRow,
  showEmptyContent,
  preOpFocused,
  pacuFocused,
  messagingView,
  staffMode,
}) => (
  <PanelContainer
    fusePanels={fusePanels}
    lastInRow={lastInRow}
    title={name}
    capacity={capacity}
    patients={patientsCount}
    messagingView={messagingView}
  >
    {staffMode ? (
      <StaffShifts isFooter={true} staffShifts={mockStaff} isKiosk={true} editableStaff={false} type="RoomShift" />
    ) : (
      <Slots patients={(patients && patients.length) || 0}>
        {patients && patients.length > 0 ? (
          <Carousel size={carouselSize ?? 5} interval={7000}>
            {patients.map(patient => (
              <AnimatedPatientSlot
                caretakerMessage={get(patient, 'caretakerMessages', null)}
                key={patient.id}
                status={patient.monitorStatus}
                procedure={get(patient, 'procedureType.name')}
                physician={get(patient, 'physician.name')}
                patient={patient.name}
                patientId={patient.id}
                patientType={patient.type}
                patientData={patient}
                bed={showBed ? patient.bed : undefined}
                showPriority={showPriority}
                priority={patient?.priority}
                showPreOpNote={showPreOpNote}
                showPacuNote={showPacuNote}
                preOpNote={patient?.preOpNote}
                pacuNote={patient?.pacuNote}
                timeEntered={new Date(patient.timeEntered)}
                ready={highlightReady && get(patient, 'ready')}
                readyForSurgeon={get(patient, 'readyForSurgeon')}
                readyForOr={get(patient, 'readyForOr')}
                familyReady={
                  roomType === PACU
                    ? patient?.familyReadyPACU
                    : roomType === POST_OP
                    ? patient?.familyReadyPOSTOP
                    : undefined
                }
                frameColor={getFrameColor(roomType, patient?.log)}
                isHoldProcedure={get(patient, 'isHoldProcedure')}
                isBlockNerve={get(patient, 'isBlockNerve')}
                expectedExitTime={get(patient, 'expectedExitTime')}
                screenType={screenType}
                preOpFocused={preOpFocused}
                hideChart={true}
                isDPU={isPreOpAsDPU(roomType, getLogEntries(patient))}
                blockNerveFollow={patient?.blockNerveFollow}
                called={patient?.called}
                isPreOp={roomType === PRE_OP}
                messagingView={messagingView}
              />
            ))}
          </Carousel>
        ) : (
          showEmptyContent && (
            <PoseGroup animateOnMount>
              <AnimatedPanelEmpty key="empty" image={image} text="Waiting for patients…" />
            </PoseGroup>
          )
        )}
      </Slots>
    )}
  </PanelContainer>
);

export default Panel;
