import React from 'react';
import { useValue } from '../../ValueContext';
import styles from '../../styles';
import MedicalPassportField from '../../components/MedicalPassportField';
import MedicalPassportCheckbox from '../../components/MedicalPassportCheckbox';
import get from 'lodash/get';
import toAge from '../../../../../../questionnaire/toAge';
import toSex from '../../../../../../questionnaire/toSex';
import toBMI, { calculateBMI } from '../../../../../../questionnaire/toBMI';
import toWeight from '../../../../../../questionnaire/toWeight';
import toHeight from '../../../../../../questionnaire/toHeight';
import getAgeAndBmi from './pageUtils';
import toHighlightsString2 from '../../../../../../questionnaire/toHighlightsString2';
import MedicalPassportTextArea2 from '../../components/MedicalPassportTextArea2';
import toStringWithoutQuestions2 from '../../../../../../questionnaire/toStringWithoutQuestions2';
import Barcode from '../../../Barcode';

const additionalMedication = ({ formName, pageNumber, showQRCode, defaultValue, answers, hospitalName }) => {
  const providerId = defaultValue?.procedure?.patient?.providerId;
  const { questionnaireAnswers } = useValue();

  const { age } = getAgeAndBmi(defaultValue?.demographicInformation?.dob, defaultValue?.demographicInformation?.bmi);
  const bmi = calculateBMI(
    parseInt(defaultValue?.demographicInformation?.bmi?.weightLb, 10),
    parseInt(defaultValue?.demographicInformation?.bmi?.heightFt, 10),
    parseInt(defaultValue?.demographicInformation?.bmi?.heightIn, 10)
  );

  const getMedicationList = answers => {
    const [takesMedication, medicationAnswer] =
      get(answers, 'sections.medications.schema.medicationList.yes', []) || [];
    return [takesMedication, get(medicationAnswer, 'list', [])];
  };

  const getWeightLossList = answers => {
    const [takesWeightLoss, WeightLossAnswer] = get(answers, 'sections.medications.schema.weightloss.yes', []) || [];
    return [takesWeightLoss, get(WeightLossAnswer, 'list', [])];
  };

  const getOTC1 = answers => {
    const [takesOTC1, OTC1Answer] = get(answers, 'sections.medications.schema.OTC1.yes', []) || [];
    return [takesOTC1, get(OTC1Answer, 'list', [])];
  };

  const getOTC2 = answers => {
    const [takesOTC2, OTC2Answer] = get(answers, 'sections.medications.schema.OTC2.yes', []) || [];
    return [takesOTC2, get(OTC2Answer, 'list', [])];
  };

  const medicationrow = get(questionnaireAnswers, 'sections.medications.schema.medicationList.yes[1].list', []);
  const medicationYes = get(questionnaireAnswers, 'sections.medications.schema.medicationList.yes[1]', []);
  const medicationList = getMedicationList(questionnaireAnswers);
  const medication = medicationList[1];
  const medicationCount = medicationrow.length;

  const weightLossrow = get(questionnaireAnswers, 'sections.medications.schema.weightloss.yes[1].list', []);
  const weightLossList = getWeightLossList(questionnaireAnswers);
  const weightLoss = weightLossList[1];
  const weigtLossCount = weightLossrow.length;

  const OTC1row = get(questionnaireAnswers, 'sections.medications.schema.OTC1.yes[1].list', []);
  const OTC1List = getOTC1(questionnaireAnswers);
  const OTC1 = OTC1List[1];
  const OTC1Count = OTC1row.length;

  const OTC2row = get(questionnaireAnswers, 'sections.medications.schema.OTC2.yes[1].list', []);
  const OTC2List = getOTC2(questionnaireAnswers);
  const OTC2 = OTC2List[1];
  const OTC2Count = OTC2row.length;

  const allMedication = medication.length + weightLoss.length + OTC1.length + OTC2.length;
  const showAdditionalTable = allMedication > 15;

  const nameArr = [allMedication];
  const doseArr = [allMedication];
  const freqArr = [allMedication];
  const purposeArr = [allMedication];
  const last = [allMedication];

  for (let i = 0; i < medicationCount; i++) {
    nameArr[i] = get(medication[i], 'schema.name.text', '');
    if (get(medication[i], 'schema.dosage.option.[1].text', '') == '') {
      doseArr[i] = get(medication[i], 'schema.dosage.option', '');
    } else {
      doseArr[i] =
        get(medication[i], 'schema.dosage.option.[1].text', '') +
        ' ' +
        get(medication[i], 'schema.dosage.option.[0]', '')
          .replace(/\bmg\s*-\s*milligrams\b/g, 'mg')
          .replace(/\bmcg\s*-\s*micrograms\b/g, 'mcg')
          .replace(/\bmeq\s*-\s*milliequivalent\b/g, 'meq')
          .replace(/\bmL\s*-\s*milliliters\b/g, 'mL');
    }
    freqArr[i] = get(medication[i], 'schema.frequency.option', '');
    purposeArr[i] = get(medication[i], 'schema.purpose.text', '');
    last[i] = get(medication[i], 'schema.last.text', '');
  }
  for (let i = 0; i < weigtLossCount; i++) {
    nameArr[medicationCount + i] = get(weightLoss[i], 'schema.weight_name.text', '');
    if (get(weightLoss[i], 'schema.weight_dosage.option.[1].text', '') === '') {
      doseArr[medicationCount + i] = get(weightLoss[i], 'schema.weight_dosage.option', '');
    } else {
      doseArr[medicationCount + i] =
        get(weightLoss[i], 'schema.weight_dosage.option.[1].text', '') +
        ' ' +
        get(weightLoss[i], 'schema.weight_dosage.option.[0]', '')
          .replace(/\bmg\s*-\s*milligrams\b/g, 'mg')
          .replace(/\bmcg\s*-\s*micrograms\b/g, 'mcg')
          .replace(/\bmeq\s*-\s*milliequivalent\b/g, 'meq')
          .replace(/\bmL\s*-\s*milliliters\b/g, 'mL');
    }
    freqArr[medicationCount + i] = get(weightLoss[i], 'schema.frequency.option', '');
    purposeArr[medicationCount + i] = get(weightLoss[i], 'schema.purpose.text', '');
    last[medicationCount + i] =
      get(weightLoss[i], 'schema.weight_lastUse.date.month', '') +
      '/' +
      get(weightLoss[i], 'schema.weight_lastUse.date.day', '') +
      '/' +
      get(weightLoss[i], 'schema.weight_lastUse.date.year', '');
  }
  for (let i = 0; i < OTC1Count; i++) {
    nameArr[medicationCount + weigtLossCount + i] = get(OTC1[i], 'schema.OverTheCounter.text', '');
    doseArr[medicationCount + weigtLossCount + i] = '';
    freqArr[medicationCount + weigtLossCount + i] = '';
    purposeArr[medicationCount + weigtLossCount + i] = '';
    last[medicationCount + weigtLossCount + i] = '';
  }
  for (let i = 0; i < OTC2Count; i++) {
    nameArr[medicationCount + weigtLossCount + OTC1Count + i] = get(OTC2[i], 'schema.vitamin.text', '');
    doseArr[medicationCount + weigtLossCount + OTC1Count + i] = '';
    freqArr[medicationCount + weigtLossCount + OTC1Count + i] = '';
    purposeArr[medicationCount + weigtLossCount + OTC1Count + i] = '';
    last[medicationCount + weigtLossCount + OTC1Count + i] = '';
  }

  const allergies = get(questionnaireAnswers, 'sections.allergies.schema.q1.checkboxes', {});
  const latex = get(allergies, 'Latex[0]');
  let medicationFlag = false;
  if (allMedication == 0) {
    medicationFlag = true;
  }
  let latexFlag = false;
  if (latex === true) {
    latexFlag = true;
  }
  if (medicationFlag) {
    nameArr[0] = '';
    doseArr[0] = '';
    freqArr[0] = '';
    purposeArr[0] = '';
    last[0] = '';
  }

  let arraySize = 17;
  let arraySize2 = 17;

  for (let i = 0; i < 17; i++) {
    if (nameArr[i] && nameArr[i].length > 38) {
      if (arraySize > 12) {
        arraySize -= 1;
      } else {
        break;
      }
    } else if (doseArr[i] && doseArr[i].length > 20) {
      if (arraySize > 12) {
        arraySize -= 1;
      } else {
        break;
      }
    }
  }

  for (let i = arraySize; i < allMedication; i++) {
    if (nameArr[i] && nameArr[i].length > 38) {
      if (arraySize2 > 12) {
        arraySize2 -= 1;
      } else {
        break;
      }
    } else if (doseArr[i] && doseArr[i].length > 20) {
      if (arraySize2 > 12) {
        arraySize2 -= 1;
      } else {
        break;
      }
    }
  }

  if (allMedication <= arraySize) {
    return '';
  } else {
    return (
      <div
        id="additionalMedication"
        className="page"
        style={{ height: '100%', paddingBottom: '0', fontFamily: 'Arial' }}
      >
        <div style={{ minHeight: '55rem', paddingBottom: '0' }}>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '50% 50%',
              gridGap: '5px',
              alignContent: 'start',
              fontSize: '12px',
            }}
          >
            <div style={styles.div}>
              <b>
                <div style={{ fontSize: '12px', paddingBottom: '0', lineHeight: '14px' }}>
                  {(hospitalName || '').toUpperCase()}
                </div>
                <div style={{ fontSize: '20px', paddingBottom: '0' }}>
                  Medication Reconciliation /<br /> Discharge Medication Orders
                </div>
              </b>
              <div style={{ fontSize: '12px' }}>
                <MedicalPassportTextArea2
                  name="procedure.name"
                  label="Procedure"
                  style={{ display: 'flex' }}
                  fontSize={12}
                  nobackground
                />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                alignItems: 'flex-end',
              }}
            >
              <div>
                {showQRCode && (
                  <Barcode
                    formName={formName}
                    pageNumber={pageNumber}
                    providerId={defaultValue?.procedure?.patient?.providerId}
                    serviceTime={defaultValue?.procedure?.serviceTime}
                  />
                )}
              </div>
              <div
                style={{
                  fontSize: '12px',
                  border: '1px solid black',
                  padding: '5px',
                  lineHeight: '15px',
                }}
              >
                <MedicalPassportField
                  name="procedure.patient.name"
                  label="Patient"
                  fontWeight={'bold'}
                  fontSize={14}
                  nobackground
                />
                <MedicalPassportField name="procedure.patient.providerId" label="MRN#" fontSize={12} nobackground />
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '40% 60%',
                    alignContent: 'start',
                  }}
                >
                  <MedicalPassportField
                    name="dob"
                    label="DOB"
                    path={['demographicInformation', 'dob']}
                    fontSize={12}
                    nobackground
                  />
                  <MedicalPassportField
                    name="age"
                    label="Age"
                    path={['demographicInformation', 'dob']}
                    reducer={toAge}
                    warning={age > 70}
                    fontSize={12}
                    nobackground
                  />
                  <MedicalPassportField name="procedure.serviceTime" label="DOS" fontSize={12} nobackground />
                  <MedicalPassportField
                    name="sex"
                    label="Sex"
                    path={['demographicInformation', 'sex']}
                    reducer={toSex}
                    fontSize={12}
                    nobackground
                  />
                </div>
                <MedicalPassportField name="procedure.physician.name" label="Surgeon" fontSize={12} nobackground />
              </div>
            </div>
          </div>
          <div style={{ ...styles.twoEqualColumnsNoBorder, fontSize: '12px' }}>
            <div></div>
            <div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <MedicalPassportField
                  name="ht"
                  label="Ht"
                  path={['basicscreening', 'bmi']}
                  reducer={toHeight}
                  fontSize={14}
                  nobackground
                />
                <MedicalPassportField
                  name="wt"
                  label="Wt"
                  path={['basicscreening', 'bmi']}
                  reducer={toWeight}
                  fontSize={14}
                  nobackground
                />
                <MedicalPassportField
                  name="bmi"
                  label="BMI"
                  path={['basicscreening', 'bmi']}
                  reducer={toBMI}
                  warning={bmi !== '-' ? bmi > 40 : false}
                  fontSize={14}
                  nobackground
                />
              </div>
            </div>
          </div>
          <div style={{ ...styles.center, fontSize: '14px' }}>
            <div style={{ display: 'flex', alignItems: 'left' }}>
              <input
                type="checkbox"
                name="page8.latex"
                label="Latex Allergy / Sensitivity"
                id="id"
                checked={latexFlag}
              />
              Latex Allergy / Sensitivity
            </div>
            <div style={{ minHeight: '480px' }}>
              <strong>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'left',
                    fontSize: '14px',
                    borderBottom: '1px solid black',
                    marginBottom: '2px',
                  }}
                >
                  Allergies:
                </div>
              </strong>
              <div>
                <MedicalPassportTextArea2
                  name="allergies1"
                  path={['allergies']}
                  reducer={toStringWithoutQuestions2}
                  warning={true}
                  fontSize={13}
                  nobackground
                />
              </div>
              <br></br>

              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '35% 16% 18% 13% 11% 8%',
                  borderBottom: 0,
                  fontSize: '13px',
                }}
              >
                <div
                  style={{
                    display: 'grid',
                    alignContent: 'start',
                    marginTop: 5,
                  }}
                >
                  <b>HOME MEDICATIONS</b>
                  <div
                    style={{
                      gridColumn: '1 / span 2',
                      height: '1px',
                      background: 'black',
                    }}
                  />
                </div>
                <div
                  style={{
                    display: 'grid',
                    alignContent: 'start',
                    marginTop: 5,
                  }}
                >
                  <b>DOSE/ROUTE</b>
                  <div
                    style={{
                      gridColumn: '1 / span 2',
                      height: '1px',
                      background: 'black',
                    }}
                  />
                </div>
                <div
                  style={{
                    display: 'grid',
                    alignContent: 'start',
                    marginTop: 5,
                  }}
                >
                  <b>FREQUENCY</b>
                  <div
                    style={{
                      gridColumn: '1 / span 2',
                      height: '1px',
                      background: 'black',
                    }}
                  />
                </div>
                <div
                  style={{
                    display: 'grid',
                    alignContent: 'start',
                    marginTop: 5,
                  }}
                >
                  <b>INDICATION</b>
                  <div
                    style={{
                      gridColumn: '1 / span 2',
                      height: '1px',
                      background: 'black',
                    }}
                  />
                </div>
                <div
                  style={{
                    display: 'grid',
                    alignContent: 'start',
                    marginTop: 5,
                  }}
                >
                  <b>LAST DOSE</b>
                  <div
                    style={{
                      gridColumn: '1 / span 2',
                      height: '1px',
                      background: 'black',
                    }}
                  />
                </div>
                <div
                  style={{
                    display: 'grid',
                    alignContent: 'start',
                    marginTop: 5,
                  }}
                >
                  <b>CONTINUE?</b>
                  <div
                    style={{
                      gridColumn: '1 / span 2',
                      height: '1px',
                      background: 'black',
                    }}
                  />
                </div>
              </div>
              <div style={{ minHeight: '350px' }}>
                {[...Array(arraySize2)].map((_, i) => (
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '35% 16% 16% 13% 12% 8%',
                      borderBottom: 0,
                      gridGap: '1px',
                    }}
                  >
                    <MedicalPassportTextArea2
                      name={`page8.c${i + arraySize}.r1`}
                      value={nameArr[i + arraySize]}
                      fontSize={'11.5px'}
                      nobackground
                      style={{ borderBottom: '1px solid lightgray' }}
                    />
                    <MedicalPassportTextArea2
                      name={`page8.c${i + arraySize}.r2`}
                      value={doseArr[i + arraySize]}
                      fontSize={'11.5px'}
                      nobackground
                      style={{ borderBottom: '1px solid lightgray' }}
                    />
                    <MedicalPassportTextArea2
                      name={`page8.c${i + arraySize}.r3`}
                      value={freqArr[i + arraySize]}
                      fontSize={'11.5px'}
                      nobackground
                      style={{ borderBottom: '1px solid lightgray' }}
                    />
                    <MedicalPassportTextArea2
                      name={`page8.c${i + arraySize}.r4`}
                      value={purposeArr[i + arraySize]}
                      fontSize={'11.5px'}
                      nobackground
                      style={{ borderBottom: '1px solid lightgray' }}
                    />
                    <MedicalPassportTextArea2
                      name={`page8.c${i + arraySize}.r5`}
                      value={last[i + arraySize]}
                      fontSize={'11.5px'}
                      nobackground
                      style={{ borderBottom: '1px solid lightgray' }}
                    />
                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '50% 50%',
                        fontSize: '11.5px',
                        borderBottom: '1px solid lightgray',
                      }}
                    >
                      <MedicalPassportCheckbox name={`page8.c${i + arraySize}.r6`} label="Y" />
                      <MedicalPassportCheckbox name={`page8.c${i + arraySize}.r7`} label="N" />
                    </div>
                  </div>
                ))}
              </div>

              <div style={{ fontSize: '12px', paddingTop: '2px', textAlign: 'left' }}>
                <div>
                  <b>PRESCRIPTIONS GIVEN AT DISCHARGE:</b>
                </div>
                <MedicalPassportCheckbox
                  name="page8.discharge1"
                  label="I have reviewed the above home medications and want the patient to resume their current
                    regimen unless I have checked to stop it."
                />
                <MedicalPassportCheckbox
                  name="page8.discharge2"
                  label="Fill the prescription(s) your surgeon has given you and take as directed. Remember to increase
                    fluid intake while on narcotics. Pain medication may upset your stomach, so take medication with food.
                    You may experience drowsiness and dizziness while taking pain medication; therefore, you should not drive,
                    operate, machinery, or drink alcoholic beverages while taking narcotics."
                />
                <MedicalPassportCheckbox
                  name="page8.discharge3"
                  label="Do not take Tylenol with the prescribed narcotic."
                />
                <MedicalPassportCheckbox
                  name="page8.discharge4"
                  label="If you have been given an antibiotic to decrease the possibility of infection after surgery, take
                    as directed until gone. Prescriptions you have been given may interact with medications you
                    currently take. Consult with pharmacist."
                />
              </div>
            </div>
            <div style={{ ...styles.darkGreyBackground, fontSize: '13px', marginTop: '2px' }}>
              PRESCRIPTIONS GIVEN AT DISCHARGE
            </div>
            <div style={{ fontSize: '12px' }}>
              <MedicalPassportCheckbox
                name="page11.dischargeMedi"
                label="Prescriptions have been E-scribed to your pharmacy by your doctor"
              />
            </div>

            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '38% 9% 9% 10% 14% 11% 9%',
                borderBottom: 0,
                fontSize: '12px',
                marginBottom: '1px',
              }}
            >
              <div
                style={{
                  display: 'grid',
                  alignContent: 'start',
                  marginTop: 5,
                }}
              >
                <b>MEDICATION</b>
                <div
                  style={{
                    gridColumn: '1',
                    height: '1px',
                    background: 'black',
                  }}
                />
              </div>
              <div
                style={{
                  display: 'grid',
                  alignContent: 'start',
                  marginTop: 5,
                }}
              >
                <b>DOSAGE</b>
                <div
                  style={{
                    gridColumn: '1',
                    height: '1px',
                    background: 'black',
                  }}
                />
              </div>
              <div
                style={{
                  display: 'grid',
                  alignContent: 'start',
                  marginTop: 5,
                }}
              >
                <b>ROUTE</b>
                <div
                  style={{
                    gridColumn: '1',
                    height: '1px',
                    background: 'black',
                  }}
                />
              </div>
              <div
                style={{
                  display: 'grid',
                  alignContent: 'start',
                  marginTop: 5,
                }}
              >
                <b>FREQUENCY</b>
                <div
                  style={{
                    gridColumn: '1',
                    height: '1px',
                    background: 'black',
                  }}
                />
              </div>
              <div
                style={{
                  display: 'grid',
                  alignContent: 'start',
                  marginTop: 5,
                }}
              >
                <b>INDICATION</b>
                <div
                  style={{
                    gridColumn: '1',
                    height: '1px',
                    background: 'black',
                  }}
                />
              </div>
              <div
                style={{
                  display: 'grid',
                  alignContent: 'start',
                  marginTop: 5,
                }}
              >
                <b>QUANTITY</b>
                <div
                  style={{
                    gridColumn: '1',
                    height: '1px',
                    background: 'black',
                  }}
                />
              </div>
              <div
                style={{
                  display: 'grid',
                  alignContent: 'start',
                  marginTop: 5,
                }}
              >
                <b>REFILLS</b>
                <div
                  style={{
                    gridColumn: '1',
                    height: '1px',
                    background: 'black',
                  }}
                />
              </div>
            </div>
            <div style={{ ...styles.sevenNotEqualColumns, marginBottom: '1px' }}>
              <MedicalPassportField name="page8.c18.r1" fontSize={12} nobackground underline />
              <MedicalPassportField name="page8.c18.r2" fontSize={12} nobackground underline />
              <MedicalPassportField name="page8.c18.r3" fontSize={12} nobackground underline />
              <MedicalPassportField name="page8.c18.r4" fontSize={12} nobackground underline />
              <MedicalPassportField name="page8.c18.r5" fontSize={12} nobackground underline />
              <MedicalPassportField name="page8.c18.r6" fontSize={12} nobackground underline />
              <MedicalPassportField name="page8.c18.r7" fontSize={12} nobackground underline />
            </div>

            <div style={{ ...styles.sevenNotEqualColumns, marginBottom: '1px' }}>
              <MedicalPassportField name="page8.c19.r1" fontSize={12} nobackground underline />
              <MedicalPassportField name="page8.c19.r2" fontSize={12} nobackground underline />
              <MedicalPassportField name="page8.c19.r3" fontSize={12} nobackground underline />
              <MedicalPassportField name="page8.c19.r4" fontSize={12} nobackground underline />
              <MedicalPassportField name="page8.c19.r5" fontSize={12} nobackground underline />
              <MedicalPassportField name="page8.c19.r6" fontSize={12} nobackground underline />
              <MedicalPassportField name="page8.c19.r7" fontSize={12} nobackground underline />
            </div>

            <div style={{ ...styles.sevenNotEqualColumns, marginBottom: '1px' }}>
              <MedicalPassportField name="page8.c20.r1" fontSize={12} nobackground underline />
              <MedicalPassportField name="page8.c20.r2" fontSize={12} nobackground underline />
              <MedicalPassportField name="page8.c20.r3" fontSize={12} nobackground underline />
              <MedicalPassportField name="page8.c20.r4" fontSize={12} nobackground underline />
              <MedicalPassportField name="page8.c20.r5" fontSize={12} nobackground underline />
              <MedicalPassportField name="page8.c20.r6" fontSize={12} nobackground underline />
              <MedicalPassportField name="page8.c20.r7" fontSize={12} nobackground underline />
            </div>
            <div style={{ ...styles.sevenNotEqualColumns, marginBottom: '1px' }}>
              <MedicalPassportField name="page8.c21.r1" fontSize={12} nobackground underline />
              <MedicalPassportField name="page8.c21.r2" fontSize={12} nobackground underline />
              <MedicalPassportField name="page8.c21.r3" fontSize={12} nobackground underline />
              <MedicalPassportField name="page8.c21.r4" fontSize={12} nobackground underline />
              <MedicalPassportField name="page8.c21.r5" fontSize={12} nobackground underline />
              <MedicalPassportField name="page8.c21.r6" fontSize={12} nobackground underline />
              <MedicalPassportField name="page8.c21.r7" fontSize={12} nobackground underline />
            </div>
            <div style={{ ...styles.sevenNotEqualColumns, marginBottom: '1px' }}>
              <MedicalPassportField name="page8.c22.r1" fontSize={12} nobackground underline />
              <MedicalPassportField name="page8.c22.r2" fontSize={12} nobackground underline />
              <MedicalPassportField name="page8.c22.r3" fontSize={12} nobackground underline />
              <MedicalPassportField name="page8.c22.r4" fontSize={12} nobackground underline />
              <MedicalPassportField name="page8.c22.r5" fontSize={12} nobackground underline />
              <MedicalPassportField name="page8.c22.r6" fontSize={12} nobackground underline />
              <MedicalPassportField name="page8.c22.r7" fontSize={12} nobackground underline />
            </div>
            <div style={{ ...styles.sevenNotEqualColumns, marginBottom: '1px' }}>
              <MedicalPassportField name="page8.c23.r1" fontSize={12} nobackground underline />
              <MedicalPassportField name="page8.c23.r2" fontSize={12} nobackground underline />
              <MedicalPassportField name="page8.c23.r3" fontSize={12} nobackground underline />
              <MedicalPassportField name="page8.c23.r4" fontSize={12} nobackground underline />
              <MedicalPassportField name="page8.c23.r5" fontSize={12} nobackground underline />
              <MedicalPassportField name="page8.c23.r6" fontSize={12} nobackground underline />
              <MedicalPassportField name="page8.c23.r7" fontSize={12} nobackground underline />
            </div>
            <br />
            <div style={styles.signatureBox1}>
              <div>
                <div style={{ paddingTop: '24px', borderBottom: '2px solid black' }}></div>
                <div style={{ textAlign: 'left', fontSize: '12.5px' }}>
                  <span style={{ marginLeft: '12.5px' }}>Physician Signature</span>
                  <span style={{ marginLeft: '190px' }}>Date/Time</span>
                </div>
              </div>
              <div>
                <div style={{ paddingTop: '24px', borderBottom: '2px solid black', marginLeft: '30px' }}></div>
                <div style={{ textAlign: 'left', fontSize: '12.5px' }}>
                  <span style={{ marginLeft: '34px' }}> Pre-Op RN Signature </span>
                  <span style={{ marginLeft: '150px' }}>Date/Time</span>
                </div>
              </div>
            </div>
            <div style={styles.signatureBox1}>
              <div>
                <div style={{ paddingTop: '24px', borderBottom: '2px solid black' }}></div>
                <div style={{ textAlign: 'left', fontSize: '12.5px' }}>
                  <span style={{ marginLeft: '12.5px' }}>Patient / Responsible Adult Signature</span>
                  <span style={{ marginLeft: '95px' }}>Date/Time</span>
                </div>
              </div>
              <div>
                <div style={{ paddingTop: '24px', borderBottom: '2px solid black', marginLeft: '30px' }}></div>
                <div style={{ textAlign: 'left', fontSize: '12.5px' }}>
                  <span style={{ marginLeft: '33px' }}> PACU RN Signature</span>
                  <span style={{ marginLeft: '160px' }}>Date/Time</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer
          style={{
            display: 'grid',
            gridTemplateColumns: '13.25% 14.5% 11.5% 17% 0.05% 14.5% 29%',
            fontSize: '11px',
            height: '20px',
          }}
        >
          <div style={{ paddingTop: '3px' }}>Provided by Patient{'('}</div>
          <MedicalPassportField name="questionnaire.firstSubmittedAt" fontSize={11} nobackground />
          <div style={{ paddingTop: '3px' }}>{')'} Last updated by</div>
          <MedicalPassportField name="procedure.patient.name" fontSize={11} nobackground />
          <div style={{ paddingTop: '3px' }}>{'('}</div>
          <MedicalPassportField name="questionnaire.submittedAt" fontSize={11} nobackground />
          <div>
            {')'}{' '}
            <span style={{ fontSize: '14px', marginLeft: '130px' }}>
              <b>Ospitek, Inc</b>
            </span>
          </div>
        </footer>
      </div>
    );
  }
};

export default additionalMedication;
