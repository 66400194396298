import React, { ReactNode } from 'react';
import Box from '@material-ui/core/Box';
import { PieChartData } from './PieChart';
import { ResponsiveContainer } from 'recharts';
import { FormControl, Grid, MenuItem, Paper, Select } from '@material-ui/core';
import AnalyticsPanelTitle from '../components/AnalyticsPanelTitle';
import CompareBarChart from './charts/CompareBarChart';
import BarCleaningByOr from './charts/BarCleaningByOr';
import BarTurnoverByOr from './charts/BarTurnoverByOr';
import BarUtilizationByOr from './charts/BarUtilizationByOr';
import ORPerformancePieChart from './charts/ORPerformancePieChart';
import AnalyticsPanel from '../components/AnalyticsPanel';

interface PieChartsProps {
  data: PieChartData[];
}

export const valueNames = {
  inOr: 'In OR',
  inSurgery: 'In Surgery',
  closing: 'Closing',
  procedureComplete: 'Post Closing',
  cleaning: 'Cleaning',
  orReady: 'OR Prepping',
  turnover: 'Turnover',
  pipoUtilization: 'OR % Utilization',
};

export const ChartWrapper = ({
  title,
  rightTitle,
  header,
  children,
  height,
}: {
  title: string;
  rightTitle?: string;
  header?: ReactNode;
  children: ReactNode;
  height?: number;
}) => (
  <Paper style={{ width: '100%', height: '100%' }}>
    <Box p={2}>
      <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
        <AnalyticsPanelTitle gutterBottom>{title}</AnalyticsPanelTitle>
        {rightTitle && <AnalyticsPanelTitle gutterBottom>{rightTitle}</AnalyticsPanelTitle>}
      </Box>
      {header}
      <Box width="100%">{children}</Box>
    </Box>
  </Paper>
);

export const COLORS = ['#2B3C87', '#74AC48', '#B7DD9A', '#A6AFD9', '#6A78B6', '#485796', '#2B3C87'];
export const OR_TURNOVER_COLORS = ['#ff9924'];

const PieCharts = (props: PieChartsProps) => {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const data = props.data;

  const transformedData = data.map(d => [
    { name: 'Patient Prepping', value: d.inOr },
    { name: 'In Surgery', value: d.inSurgery },
    { name: 'Closing', value: d.closing },
    { name: 'Post Closing', value: d.procedureComplete },
    { name: 'Cleaning', value: d.cleaning },
    { name: 'OR Prepping', value: d.orReady },
  ]);

  return (
    <Grid container spacing={1} alignItems="stretch" style={{ marginBottom: 8 }}>
      <Grid item lg={3} md={6} xs={12}>
        <AnalyticsPanel title="OR Performance">
          <Box width="100%" mb={2}>
            <FormControl fullWidth size="small" variant="outlined">
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={activeIndex}
                onChange={e => setActiveIndex(e.target.value as number)}
              >
                {data.map((d, i) => (
                  <MenuItem key={i} value={i}>
                    {d.title}
                  </MenuItem>
                ))}
                <MenuItem value={transformedData.length}>Compare</MenuItem>
              </Select>
            </FormControl>
          </Box>
          {activeIndex === transformedData.length ? (
            <ResponsiveContainer minWidth={200} width="100%" height="100%" aspect={1}>
              <CompareBarChart data={data} />
            </ResponsiveContainer>
          ) : (
            <ORPerformancePieChart
              data={data}
              activeIndex={activeIndex}
              name={`${data[activeIndex].title}`}
              subtitle={'performance'}
              aspect={1}
              minWidth={200}
              isAnimationActive={false}
              innerRadius={'50%'}
              outerRadius={'80%'}
              outerRingInnerRadius={'85%'}
              outerRingOuterRadius={'90%'}
              orTurnoverInnerRadius={'95%'}
              orTurnoverOuterRadius={'100%'}
            />
          )}
        </AnalyticsPanel>
      </Grid>

      <Grid item lg={3} md={6} xs={12}>
        <BarUtilizationByOr data={data} />
      </Grid>
      <Grid item lg={3} md={6} xs={12}>
        <BarTurnoverByOr data={data} />
      </Grid>
      <Grid item lg={3} md={6} xs={12}>
        <BarCleaningByOr data={data} />
      </Grid>
    </Grid>
  );
};

export default PieCharts;
