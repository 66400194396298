import { graphql } from '@apollo/client/react/hoc';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router';
import { compose, mapProps } from 'recompose';
import styled, { css } from 'styled-components';
import closing from '../../../../assets/sound/closing.mp3';

import discharge from '../../../../assets/sound/discharge.mp3';
import holdProcedure from '../../../../assets/sound/holdProcedure.mp3';
import blockNerve from '../../../../assets/sound/holdProcedure.mp3'; // change to blockNerve sound
import help from '../../../../assets/sound/holdProcedure.mp3'; // change to help sound
import orReady from '../../../../assets/sound/orReady.mp3';
import readyToPickup from '../../../../assets/sound/readyToPickup.mp3';
import waitingReady from '../../../../assets/sound/waitingReady.mp3';
import { withScope } from '../../../../contexts/ScopeContext';
import { getGlobalAvgTimesConfiguration } from '../../../../graph/procedureTypes';
import { listSubscription } from '../../../../graph/rooms';
import { isArray, isDefinedAndNotNull } from '../../../../se/utilities/check';
import { getNestedValue } from '../../../../se/utilities/data/object';
import responsive from '../../../../se/utilities/responsive';
import { withSession } from '../../../../state/Session';
import ClientUpdater, { ClientUpdaterPresetTvInternal } from '../../../ClientUpdater';
import { PACU, PRE_OP } from '../../../entities/room/enums';
import {
  createMapOfRoomsByType,
  mapFamilyReadyPACU,
  mapFamilyReadyPOSTOP,
  mapMonitorOperationRoom,
  mapMonitorRoom,
  mapORReady,
  mapPacuReady,
  mapPostOpReady,
  mapPrepReady,
  mapWaitingReady,
  monitorHideProcedureType,
  monitorHideProcedureTypeMessagingView,
} from '../../../entities/room/transducers';
import PanelSlots from '../../../Panel/PanelSlots';
import { unpackSessionObject } from '../../unpackSessionObject';
import MonitorContent from './MonitorContent';

export const PanelsStatus = styled.div`
  display: flex;
  margin-left: -0.25rem;
  margin-right: -0.25rem;
  flex: 1 1 50%;

  > div {
    margin: 0.25rem;
  }

  ${responsive.sm.andSmaller`
    flex-flow: column;
  `};

  ${props =>
    props.wrap &&
    css`
      flex-wrap: wrap;
      flex: 1 1 50%;
    `}

  ${props =>
    props.miniView &&
    css`
      flex: 0 0 50%;
    `}

  ${props =>
    props.moreSpace &&
    css`
      flex: 0 0 80%;
    `}
`;

export const sum = arr => arr.reduce((l, r) => l + r, 0);

const audioPool = [];
export const soundAlert = async (alert, condition) => {
  if (!condition) return;

  try {
    // Find an available audio object or create a new one
    let sound;

    // Try to find an idle sound object in the pool
    for (const pooledSound of audioPool) {
      if (pooledSound.ended || pooledSound.paused) {
        sound = pooledSound;
        break;
      }
    }

    // If no idle sound found, create a new one (with pool size limit)
    if (!sound) {
      sound = new Audio();
      audioPool.push(sound);
    }

    // Reset and play the sound
    sound.src = alert;
    await sound.play();
  } catch (e) {
    console.warn(e);
  }
};

export const makePanels = (
  room,
  carouselSize,
  panelNumber,
  hospitalId,
  imageEmpty,
  hasPreOpPriorityModule,
  hasNoteModule,
  messagingView,
  screenType,
  staffMode
) => {
  const { name, capacity, patients, type } = room;
  const newPanels = [];

  for (let i = 0; i < panelNumber; i++) {
    const oneEmptyPanel = panelNumber === 1 && patients?.length === 0;
    const twoEmptyPanel = panelNumber === 2 && patients?.length === 0 && i === 0;
    const threeEmptyPanel = panelNumber === 3 && patients?.length === 0 && i === 1;
    newPanels.push(
      <PanelSlots
        key={i}
        capacity={capacity}
        patientsCount={patients?.length || 0}
        patients={
          panelNumber === 1
            ? patients
            : patients.slice(i * carouselSize, i + 1 !== panelNumber ? (i + 1) * carouselSize : undefined)
        }
        name={i === 0 ? name : undefined}
        roomType={type}
        image={imageEmpty}
        showBed={true}
        showPriority={hasPreOpPriorityModule}
        showPreOpNote={type === PRE_OP && hasNoteModule}
        showPacuNote={type === PACU && hasNoteModule}
        highlightReady
        carouselSize={carouselSize}
        fusePanels={i + 1 !== panelNumber}
        lastInRow={i + 1 === panelNumber}
        showEmptyContent={oneEmptyPanel || twoEmptyPanel || threeEmptyPanel}
        messagingView={messagingView}
        screenType={screenType}
        staffMode={staffMode}
      />
    );
  }

  return newPanels;
};

class Monitor extends React.Component {
  UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
    if (!this.props.loading) {
      if (this.prepWaitingNumberFromProps(nextProps) > this.prepWaitingNumberFromProps(this.props)) {
        this.readyForPrepReadyAlert();
      }

      if (this.readyForORNumberFromProps(nextProps) > this.readyForORNumberFromProps(this.props)) {
        this.readyForORAlert();
      }

      if (this.readyForSurgeonNumberFromProps(nextProps) > this.readyForSurgeonNumberFromProps(this.props)) {
        this.readyForSurgeonAlert();
      }

      if (this.holdProcedureNumberFromProps(nextProps) > this.holdProcedureNumberFromProps(this.props)) {
        this.holdProcedureAlert();
      }

      if (this.blockNerveNumberFromProps(nextProps) > this.blockNerveNumberFromProps(this.props)) {
        this.blockNerveAlert();
      }
      if (this.blockNerveNumberFromProps2(nextProps) > this.blockNerveNumberFromProps2(this.props)) {
        this.blockNerveAlert();
      }

      if (this.helpNumberFromProps(nextProps) > this.helpNumberFromProps(this.props)) {
        this.helpAlert();
      }

      if (this.pacuWaitingNumberFromProps(nextProps) > this.pacuWaitingNumberFromProps(this.props)) {
        this.readyToSeeFamilyAlert();
      }

      if (this.postOpWaitingNumberFromProps(nextProps) > this.postOpWaitingNumberFromProps(this.props)) {
        this.readyToSeeFamilyAlert();
      }

      if (this.familyHereNumberFromProps(nextProps) > this.familyHereNumberFromProps(this.props)) {
        this.familyHereAlert();
      }

      if (this.readyToPickupNumberFromProps(nextProps) > this.readyToPickupNumberFromProps(this.props)) {
        this.readyToPickupAlert();
      }

      if (this.closingProps(nextProps) > this.closingProps(this.props)) {
        this.closingAlert();
      }

      if (this.patientNumberFromProps(nextProps) < this.patientNumberFromProps(this.props)) {
        this.dischargeAlert();
      }
    }
  }

  readyForPrepReadyAlert = () => soundAlert(waitingReady, getNestedValue('configuration.prepReadySound', this.props));
  readyForORAlert = () => soundAlert(orReady, getNestedValue('configuration.orReadySound', this.props));
  readyForSurgeonAlert = () =>
    soundAlert(waitingReady, getNestedValue('configuration.readyForSurgeonSound', this.props));
  holdProcedureAlert = () => soundAlert(holdProcedure, getNestedValue('configuration.holdProcedureSound', this.props));
  blockNerveAlert = () => soundAlert(blockNerve, getNestedValue('configuration.blockNerveSound', this.props));
  helpAlert = () => soundAlert(help, getNestedValue('configuration.helpSound', this.props));
  readyToSeeFamilyAlert = () => soundAlert(waitingReady, getNestedValue('configuration.pacuReadySound', this.props));
  familyHereAlert = () => soundAlert(waitingReady, getNestedValue('configuration.waitingReadySound', this.props));
  readyToPickupAlert = () => soundAlert(readyToPickup, getNestedValue('configuration.readyToPickupSound', this.props));
  dischargeAlert = () => soundAlert(discharge, getNestedValue('configuration.dischargeSound', this.props));
  closingAlert = () => soundAlert(closing, getNestedValue('configuration.closingSound', this.props));

  patientNumberFromProps = ({ operationRooms = [], waitingRoom, preOp, pacu, postOp }) =>
    sum([
      (getNestedValue('patients', waitingRoom) || []).length,
      (getNestedValue('patients', preOp) || []).length,
      (getNestedValue('patients', pacu) || []).length,
      (getNestedValue('patients', postOp) || []).length,
      sum(operationRooms.map(or => (getNestedValue('patients', or) || []).length)),
    ]);

  closingProps = ({ operationRooms = [] }) =>
    operationRooms.filter(or => getNestedValue('status', or) === 'Closing').length;
  patientRoomNumberFromProps = type => props => {
    const value = props[type];

    if (!value) {
      return 0;
    }

    return (Array.isArray(value) ? value : [value])
      .map(v => {
        const patients = getNestedValue('patients', v);

        if (Array.isArray(patients)) {
          return patients.filter(_ => _.ready).length;
        }

        return 0;
      })
      .reduce((acc, curr) => acc + curr, 0);
  };

  waitingNumberFromProps = this.patientRoomNumberFromProps('waitingRoom');
  pacuWaitingNumberFromProps = this.patientRoomNumberFromProps('pacu');
  postOpWaitingNumberFromProps = this.patientRoomNumberFromProps('postOps');
  prepWaitingNumberFromProps = this.patientRoomNumberFromProps('waitingRoom');

  readyForSurgeonNumberFromProps = props => {
    const patients = getNestedValue('patients', props['preOp']) || {};
    if (Array.isArray(patients)) {
      return patients.filter(_ => _.readyForSurgeon).length;
    }
    return 0;
  };

  holdProcedureNumberFromProps = props => {
    const patients = getNestedValue('patients', props['preOp']) || {};
    if (Array.isArray(patients)) {
      return patients.filter(_ => _.isHoldProcedure).length;
    }
    return 0;
  };

  blockNerveNumberFromProps = props => {
    const patients = getNestedValue('patients', props['preOp']) || {};
    if (Array.isArray(patients)) {
      return patients.filter(_ => _.isBlockNerve).length;
    }
    return 0;
  };

  blockNerveNumberFromProps2 = props => {
    const postOps = (props['postOps'] || []).flatMap(postOp => getNestedValue('patients', postOp) || []);
    const pacu = getNestedValue('patients', props['pacu']) || [];

    return [...postOps, ...pacu].filter(_ => Boolean(_.isBlockNerve)).length;
  };

  helpNumberFromProps = props => {
    const patients = getNestedValue('patients', props['OR']) || {};
    if (Array.isArray(patients)) {
      return patients.filter(_ => _.isHelp).length;
    }
    return 0;
  };

  readyForORNumberFromProps = props => {
    const patients = getNestedValue('patients', props['preOp']) || {};
    if (Array.isArray(patients)) {
      return patients.filter(p => p.readyForOr).length;
    }
    return 0;
  };
  familyHereNumberFromProps = props => {
    const postOps = (props['postOps'] || []).flatMap(postOp => getNestedValue('patients', postOp) || []);
    const pacu = getNestedValue('patients', props['pacu']) || [];

    return [...postOps, ...pacu].filter(
      _ => Boolean(_.familyReady) || Boolean(_.familyReadyPACU) || Boolean(_.familyReadyPOSTOP)
    ).length;
  };

  readyToPickupNumberFromProps = props => {
    const postOps = (props['postOps'] || []).flatMap(postOp => getNestedValue('patients', postOp) || []);
    const pacu = getNestedValue('patients', props['pacu']) || [];

    return [...postOps, ...pacu]?.filter(_ => !!_.caretakerMessages).length;
  };

  render() {
    const { scope, operationRooms, waitingRoom, preOp, pacu, postOps, configuration, staffMode } = this.props;
    const hospitalId = scope?.hospital?.id;
    const hasPreOpPriorityModule = scope?.hospital?.modules?.preOpPriority?.hasPreOpPriority || false;
    const hasNoteModule = scope?.hospital?.modules?.noteTablet || false;

    const showPatientTypes = configuration?.showPatientTypes;

    const filterPatients = room => {
      if (!room || !room.patients) return room;
      if (!showPatientTypes || showPatientTypes.length === 0) {
        return room;
      }
      return { ...room, patients: room.patients.filter(p => showPatientTypes.includes(p.type)) };
    };

    const filterOperationRoom = room => {
      if (!room) return room;
      if (!showPatientTypes || showPatientTypes.length === 0) {
        return room;
      }
      return {
        ...room,
        patient: room.patient && showPatientTypes.includes(room.patient.type) ? room.patient : null,
        patients: room.patients ? room.patients.filter(p => showPatientTypes.includes(p.type)) : [],
      };
    };

    const filteredOperationRooms = operationRooms.map(filterOperationRoom);
    const filteredWaitingRoom = filterPatients(waitingRoom);
    const filteredPreOp = filterPatients(preOp);
    const filteredPacu = filterPatients(pacu);
    const filteredPostOps = filterPatients(postOps);

    const roomsWithOrder = filteredOperationRooms
      .filter(room => room?.order !== null)
      .sort((a, b) => a.order - b.order);
    const roomsWithoutOrder = filteredOperationRooms.filter(room => room?.order === null);

    const orderedRooms = [
      ...roomsWithOrder,
      ...roomsWithoutOrder.filter(room => room?.name?.toUpperCase()?.startsWith('OR')),
      ...roomsWithoutOrder.filter(room => !room?.name?.toUpperCase()?.startsWith('OR')),
    ];

    return (
      <Fragment>
        <Helmet>
          <meta name="viewport" content="width=1200, initial-scale=0" />
        </Helmet>
        {staffMode ? (
          <MonitorContent
            operationRooms={orderedRooms}
            waitingRoom={filteredWaitingRoom}
            preOp={filteredPreOp}
            pacu={filteredPacu}
            postOps={filteredPostOps}
            hasPostop={scope?.hospital?.modules?.hasPostop && configuration?.showPostOp !== false}
            hospitalId={hospitalId}
            preOpFocused={configuration?.preOpFocused}
            pacuFocused={configuration?.pacuFocused}
            hasNoteModule={hasNoteModule}
            hasPreOpPriorityModule={hasPreOpPriorityModule}
            isMini={false}
            isSuperAdmin={this.props.isSuperAdmin}
            isLiaison={this.props.isLiaison}
            messagingView={configuration?.messagingScreen}
            scope={scope}
            staffMode={staffMode}
          />
        ) : (
          <MonitorContent
            operationRooms={orderedRooms}
            waitingRoom={filteredWaitingRoom}
            preOp={filteredPreOp}
            pacu={filteredPacu}
            postOps={filteredPostOps}
            hasPostop={scope?.hospital?.modules?.hasPostop && configuration?.showPostOp !== false}
            hospitalId={hospitalId}
            preOpFocused={configuration?.preOpFocused}
            pacuFocused={configuration?.pacuFocused}
            hasNoteModule={hasNoteModule}
            hasPreOpPriorityModule={hasPreOpPriorityModule}
            isMini={false}
            isSuperAdmin={this.props.isSuperAdmin}
            isLiaison={this.props.isLiaison}
            messagingView={configuration?.messagingScreen}
            scope={scope}
          />
        )}
        {[...orderedRooms, filteredWaitingRoom, filteredPreOp, filteredPacu, filteredPostOps]
          .filter(room => room && room.patients)
          .every(({ patients = [] }) => patients.length <= 0) && <ClientUpdater {...ClientUpdaterPresetTvInternal} />}
      </Fragment>
    );
  }
}

Monitor.defaultProps = {
  operationRooms: [],
  waitingRoom: {},
  pacu: {},
  preOp: {},
  postOps: [],
};

Monitor.propTypes = {
  operationRooms: PropTypes.array,
  waitingRoom: PropTypes.object,
  pacu: PropTypes.object,
  preOp: PropTypes.object,
  postOp: PropTypes.array,
};

const flattenRoomPatients = rooms =>
  rooms
    .map(_ => mapMonitorRoom(_))
    .reduce((acc, curr) => ({
      ...acc,
      patients: [...acc.patients, ...curr.patients],
      capacity: acc.capacity + curr.capacity,
    }));

const flattenRoomPatients2 = rooms => rooms.map(_ => mapMonitorRoom(_));

const injectExpectedWRDuration = (wrRoom, expectedWRDuration) => [
  {
    ...wrRoom[0],
    patients: (wrRoom[0].patients || []).map(p => ({
      ...p,
      procedureType: {
        ...p.procedureType,
        expectedWRDuration: get(expectedWRDuration, 'averageTimesConfiguration.expectedWRDuration', undefined),
      },
    })),
  },
];

export default compose(
  withRouter,
  withSession(unpackSessionObject),
  // branch(({ version }) => version === 'protobuf', renderComponent(ProtobufMonitor)),
  graphql(listSubscription),
  mapProps(({ data, ...rest }) => ({
    internalMonitor: isDefinedAndNotNull(data) ? data['internalMonitor'] || [] : [],
    error: data.error,
    loading: data.loading,
    ...rest,
  })),
  withScope,
  mapProps(({ internalMonitor, isLiaison, ...rest }) => ({
    internalMonitor: isLiaison
      ? monitorHideProcedureTypeMessagingView(internalMonitor, !getNestedValue('configuration.showProcedure', rest))
      : monitorHideProcedureType(internalMonitor, !getNestedValue('configuration.showProcedure', rest)),
    isLiaison,
    ...rest,
  })),
  mapProps(({ internalMonitor, patientNameFormat, scope, ...rest }) => ({
    ...createMapOfRoomsByType(
      internalMonitor,
      patientNameFormat,
      get(scope, 'hospital.id'),
      get(scope, 'hospital.groupId')
    ),
    scope,
    ...rest,
  })),
  graphql(getGlobalAvgTimesConfiguration),
  mapProps(({ data, operationRooms, waitingRooms, preOps, pacus, postOps, ...rest }) => ({
    ...rest,
    operationRooms: isArray(operationRooms) ? operationRooms.map(mapMonitorOperationRoom) : [],
    waitingRoom: isArray(waitingRooms) ? flattenRoomPatients(injectExpectedWRDuration(waitingRooms, data)) : undefined,
    preOp: isArray(preOps) ? flattenRoomPatients(preOps) : undefined,
    pacu: isArray(pacus) ? flattenRoomPatients(pacus) : undefined,
    // flattenRoomPatients2 will return array of post ops, flattenRoomPatients will reduce to one room
    postOps: isArray(postOps) ? flattenRoomPatients2(postOps) : undefined,
  })),
  mapProps(({ waitingRoom, operationRooms, preOp, pacu, postOps, ...rest }) => {
    // this config is named wrong showOrRooms because previously it is used only for or rooms
    const config = rest?.configuration?.showOrRooms || [];
    const showPatientTypes = rest?.configuration?.showPatientTypes;

    const isConfigEmptyOrIncludes = id => !config || config.length === 0 || config.includes(id);

    const filterPatients = room => {
      if (!room || !room.patients) return room;
      if (!showPatientTypes || showPatientTypes.length === 0) return room;
      return { ...room, patients: room.patients.filter(p => showPatientTypes.includes(p.type)) };
    };

    const filterOperationRoom = room => {
      if (!room) return room;
      if (!showPatientTypes || showPatientTypes.length === 0) return room;
      return {
        ...room,
        patient: room.patient && showPatientTypes.includes(room.patient.type) ? room.patient : null,
        patients: room.patients ? room.patients.filter(p => showPatientTypes.includes(p.type)) : [],
      };
    };

    return {
      ...rest,
      waitingRoom: isConfigEmptyOrIncludes(waitingRoom?.id) ? mapWaitingReady(filterPatients(waitingRoom)) : null,
      operationRooms:
        config && config.length > 0
          ? mapORReady(operationRooms)
              .filter(r => config.includes(r.id))
              .map(filterOperationRoom)
          : mapORReady(operationRooms).map(filterOperationRoom),
      preOp: isConfigEmptyOrIncludes(preOp?.id) ? mapPrepReady(filterPatients(preOp)) : null,
      pacu: isConfigEmptyOrIncludes(pacu?.id) ? mapFamilyReadyPACU(mapPacuReady(filterPatients(pacu))) : null,
      postOps: (postOps ?? [])
        .filter(e => isConfigEmptyOrIncludes(e?.id))
        .map(postOp => mapFamilyReadyPOSTOP(mapPostOpReady(filterPatients(postOp)))),
    };
  })
)(Monitor);
