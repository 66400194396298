import React, { FC } from 'react';
import { format } from 'date-fns';
import TableWithColumnSelector, { TableType } from '../../core/TableWithColumnSelector';
import { useQuery } from '@apollo/client';
import { ORPatientsUtilizationAnalyticsQuery } from '../../../graph/dashboard';
import { getMinutes } from './util';
import { ORPatientJourneyTableData } from '../../../types/Analytics';
import { DateRangeType } from '../../../types/utils';
import { useScope } from '../../../hooks/useScope';

const toTableData = (data: ORPatientJourneyTableData[], hasPostOp?: boolean): TableType => {
  const postOpInOutHeaders = hasPostOp ? ['POSTOP In', 'POSTOP Out'] : [];
  const postOpHeaders = hasPostOp ? ['POSTOP'] : [];
  const baseHeaders = [
    'Date of service',
    'Patient MRN',
    'Patient',
    'Procedure Type',
    'Admitted',
    'Pre Op In',
    'Pre Op Out',
    'OR In',
    'OR Out',
    'Recovery In',
    'Recovery Out',
    ...postOpInOutHeaders,
    'Discharged At',
    'Pre Op',
    'Wheels-In, Wheels-Out',
    'Recovery',
    ...postOpHeaders,
    'Pre OP to Exit',
  ];

  return {
    configHeader: [...baseHeaders],
    headers: [
      {
        id: 'h1',
        columns: [...baseHeaders],
      },
    ],
    rows: (data || []).map(e => ({
      id: e?.id.toString(),
      columns: [
        e.surgeryTime,
        e.patientProviderId || '',
        e.patient,
        e.procedureType,
        e.admittedAt,
        e.preOpIn,
        e.preOpOut,
        e.orIn,
        e.orOut,
        e.recoveryIn,
        e.recoveryOut,
        ...(hasPostOp ? [e.postOpIn] : []),
        ...(hasPostOp ? [e.postOpOut] : []),
        e.dischargedAt,
        getMinutes(e.preOpInMinutes),
        getMinutes(e.pipoInMinutes),
        getMinutes(e.recoveryInMinutes),
        ...(hasPostOp ? [getMinutes(e.postOpInMinutes)] : []),
        getMinutes(e.preOpToExitInMinutes),
      ],
    })),
  };
};

const ORPatientsUtilizationAnalytics: FC<{ orId: number; orName?: string; dateRange: DateRangeType; filter: any }> = ({
  orId,
  orName,
  dateRange,
  filter,
}) => {
  const { data } = useQuery(ORPatientsUtilizationAnalyticsQuery, {
    variables: {
      orId,
      filter,
    },
  });

  const scope = useScope();
  const hasPostOp = scope?.hospital?.modules?.hasPostop;
  const table: ORPatientJourneyTableData[] = data?.ORPatientsUtilizationAnalytics || [];

  return (
    <>
      <TableWithColumnSelector
        configName="or-patients-analysis-table-config"
        tableId="OR-Patients-table"
        tableName={`For Operation Room ${orName || ''}`}
        excelFileName={`OR-Patient-Journey-${format(dateRange.from, 'MM/DD/YYYY')}-${format(
          dateRange.to,
          'MM/DD/YYYY'
        )}`}
        {...toTableData(table, hasPostOp)}
      />
    </>
  );
};

export default ORPatientsUtilizationAnalytics;
